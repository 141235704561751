import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AppState } from '../../store/configureStore'
import { ThunkDispatch } from 'redux-thunk'
import { AppActions } from '../../types/actions'
import { signOut } from '../../actions/auth'
import { AuthState } from '../../types/Auth' 
import { bindActionCreators } from 'redux'
import './index.css'
import Logo from '../../assets/images/logo.svg'

type NavbarProps = {
}

type Props = NavbarProps & LinkStateProps & LinkDispatchProps

type NavbarState = {
  path: string;
}

class Navbar extends Component<Props> {
  state = {
    path: ''
  }

  componentDidMount() {
    const path = this.getPath()
    this.setState({ path: path })
  }

  getPath = () => {
    return window.location.pathname
  }

  handleSignOut = async () => {
    await this.props.signOut()
    window.location.href = '/'
  }

  render() {
    let email = ''
    if (this.props.auth && this.props.auth.user) {
      const { user } = this.props.auth
      email = user?.attributes?.email
    }
    
    return (
      <div className="container">
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <a className="navbar-item" href="/">
              <img
                src={Logo}
                className="logo"
                alt="Dark Launchly logo" 
              />
            </a>
          </div>

          <div id="navbarBasicExample" className="navbar-menu">
            <div className="navbar-start">
              <a href="/workspaces" className={`navbar-item ${(this.state.path === '/workspaces') ? 'active' : ''}`}>
                My workspaces
              </a>
              <a href="/flags" className={`navbar-item ${(this.state.path === '/flags') ? 'active' : ''}`}>
                All flags
              </a>
            </div>

            <div className="navbar-end">
              {
                this.props.auth.isAuthenticated &&
                <div className="navbar-item email">
                  {email}
                </div>
              }
              <div className="navbar-item">
                <div className="buttons">
                  {
                    this.props.auth.isAuthenticated &&
                    <a href="#" onClick={this.handleSignOut} className="button is-light">Sign out</a>
                  }
                  {
                    !this.props.auth.isAuthenticated &&
                    <a href="/register" className="button is-light">Register</a>
                  }
                  {
                    !this.props.auth.isAuthenticated &&
                    <a href="/login" className="button is-success">Log in</a>
                  }
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    )
  }
}

interface LinkStateProps {
  auth: AuthState
}

interface LinkDispatchProps {
  signOut: () => void
}

const mapStateToProps = (
  state: AppState,
  ownProps: NavbarProps
): LinkStateProps => ({
  auth: state.entities.auth
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: NavbarProps
): LinkDispatchProps => ({
  signOut: bindActionCreators(signOut, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar)

