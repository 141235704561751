import React from 'react'
import './index.css'
import { ICohortCriterion } from '../../types/Flag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type CohortCriterionProps = {
  cohortCriterion: ICohortCriterion;
  isEditMode: boolean;
  editCohortCriterion: (cohortCriterion: ICohortCriterion) => void;
  addCohortCriterion: (event: any) => void;
  deleteCohortCriterion: (cohortCriteriaId: number, event: any) => void;
}

const CohortCriterion: React.FC<CohortCriterionProps> = (props) => {
  const { cohortCriterion, editCohortCriterion, isEditMode } = props
  const cohortCrtierionValues = props.cohortCriterion.requiredFieldValues?.toString()

  const onSetRequiredFieldName = (event: any) => {
    const newCohortCriterion = { ...cohortCriterion, requiredFieldName: event.target.value }
    editCohortCriterion(newCohortCriterion)
  }

  const onSetCohortCrtierionValues = (event: any) => {
    const newCohortCriterion = { ...cohortCriterion, requiredFieldValues: event.target.value }
    editCohortCriterion(newCohortCriterion)
  }

  const deleteIcon = <FontAwesomeIcon icon={['fas', 'times']} />

  const cohortCriterionReadOnly = <div className="columns CohortCriterion">
    <div className="column is-6">
      <div className="field">
        <label className="label is-small">Cohort name</label>
        <div className="control">
          <input className="input is-small" type="text" value={props.cohortCriterion.requiredFieldName} readOnly />
        </div>
      </div>
    </div>
    <div className="column is-6">
      <div className="field">
        <label className="label is-small">Cohort value</label>
        <div className="control">
          <input className="input is-small" type="text" value={cohortCrtierionValues} readOnly />
        </div>
      </div>
    </div>
  </div>

  const cohortCriterionEditMode = <div className="columns CohortCriterion">
    <div className="column is-5">
      <div className="field">
        <label className="label is-small">Cohort name</label>
        <div className="control">
          <input className="input is-small" type="text" defaultValue={props.cohortCriterion.requiredFieldName} onChange={onSetRequiredFieldName} />
        </div>
      </div>
    </div>
    <div className="column is-5">
      <div className="field">
        <label className="label is-small">Cohort value</label>
        <div className="control">
          <input className="input is-small" type="text" defaultValue={cohortCrtierionValues} onChange={onSetCohortCrtierionValues} />
        </div>
      </div>
    </div>
    <div className="column is-2 delete-btn" onClick={event => props.deleteCohortCriterion(props.cohortCriterion.cohortCriteriaId, event)}>
      <button className="button is-danger is-outlined is-small">
        <span>Delete</span>
        <span className="icon is-small">
          {deleteIcon}
        </span>
      </button>
    </div>
  </div>

  const cohortCriterionContent = isEditMode ? cohortCriterionEditMode : cohortCriterionReadOnly

  return (
    cohortCriterion && cohortCriterionContent  
  )
}

export default CohortCriterion