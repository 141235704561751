import React from 'react'
import './index.css'

type ErrorMessageProps = {
  errorMessage: string
}

const ErrorMessage: React.FC<ErrorMessageProps> = (props) => {
  const { errorMessage } = props
  return (
    <div className="error-message-container">
      <div className="error-message from-server error">
        { errorMessage }
      </div>
    </div>
  )
}

export default ErrorMessage
