import React from 'react'
import './index.css'
import { Link } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import FlagCard from '../FlagCard'
import SuccessMessage from '../SuccessMessage'
import ErrorMessage from '../ErrorMessage'
import { WORKSPACE_DEPLOYMENT_SUCCESS } from '../../config/constants'
import { DEFAULT_NETWORK_ERROR } from '../../config/constants'
import { connect } from 'react-redux'
import { setWorkspaces, deployWorkspaceConfig, replaceWorkspaceConfig, showWorkspaceDiff } from '../../actions/workspaces'
import { setFlags } from '../../actions/flags'
import { Workspace } from '../../types/Workspace'
import { Flag } from '../../types/Flag'
import { AppState } from '../../store/configureStore'
import { ThunkDispatch } from 'redux-thunk'
import { AppActions } from '../../types/actions'
import { IReducerDefaultStateBase } from '../../types/reducers'
import { bindActionCreators } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type RouteParams = {
  id: string
}

interface WorkspaceDetailsProps extends RouteComponentProps<RouteParams> {
  workspace: Workspace
}

type WorkspaceDetailsState = {
  selectedConfigWorkspaceName: string;
}

type Props = WorkspaceDetailsProps & LinkStateProps & LinkDispatchProps

class WorkspaceDetails extends React.Component<Props, WorkspaceDetailsState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      selectedConfigWorkspaceName: ''
    }
  }

  handleSetWorkspaceConfig = (event: any) => {
    const newWorkspaceConfig = event.target?.value
    this.setState({ selectedConfigWorkspaceName: newWorkspaceConfig })
  }

  handleUpdateWorkspaceConfig = async () => {
    const destinationWorkspaceId = this.props.match.params.id
    const sourceWorkspaceName = this.state.selectedConfigWorkspaceName
    let sourceWorkspaceId = this.props.workspaces.allIds.find((workspaceId: string) => {
      return this.props.workspaces.byId[workspaceId].workspace === sourceWorkspaceName
    })

    if (!sourceWorkspaceId || !destinationWorkspaceId) {
      return console.log('Source or destination workspace not selected')
    }

    try {
      const response = await this.props.replaceWorkspaceConfig(sourceWorkspaceId, destinationWorkspaceId)
    } catch (error) {
      console.log(`An error has occured: ${error}`)
    }
    
  }

  async handleDeployWorkspaceConfig(workspaceName: string) {
    await this.props.deployWorkspaceConfig(workspaceName)
    await this.props.setFlags()
  }

  async handleShowWorkspaceDiff(event: any, showDiffEndpoint: string) {
    event.preventDefault()
    const workspaceId = this.props.match.params.id
    if (showDiffEndpoint) {
      try {
        await this.props.showWorkspaceDiff(showDiffEndpoint)
        this.props.history.push(`/workspaces/showDiff/${workspaceId}`)
      } catch(error) {
        console.log(`Workspace diff failed: ${error}`)
      }
    }
  }

  render() {
    const workspaceNames = this.props.workspaces.allIds.map((workspaceId: string) => {
      return this.props.workspaces.byId[workspaceId].workspace
    })
    // @ts-ignore
    let uniqueWorkspaceNames = [...new Set(workspaceNames)]
    const workspaceId = this.props.match.params.id
    const workspace: Workspace | undefined = this.props.workspaces.byId[workspaceId]
    const workspaceFlags = Object.values(this.props.flags.byId).filter((flag: Flag) => flag.workspaceId?.toString() === workspaceId)
    const hasUndeployedChanges = workspaceFlags.some((flag: Flag) => {
      return flag.hasUndeployedChange
    })
    const showDiffEndpoint = (workspace && workspace.showDiff) ? workspace.showDiff : undefined
    const addIcon = <FontAwesomeIcon icon={['fas', 'plus']} />
    return (
      <div className="container workspace-details has-background-light">
        <div className="section">
          {
            workspace &&
            <div className="columns">
              <div className="column is-3">
                <div className="has-text-left mb-5">
                  <h2>Workspace flags</h2>
                </div>
                <Link to={`/workspace/${workspaceId}/createflag`}>
                  <button className="button is-success">
                    <span className="icon">
                      { addIcon }
                    </span>
                    <span>Add flag</span>
                  </button>
                </Link>
                <div className="tile is-ancestor mt-3">
                  <div className="tile is-vertical is-parent">
                    {
                      workspaceFlags.map((flag: Flag) => {
                        return <FlagCard key={flag.id} flag={flag} />
                      })
                    }
                  </div>
                </div>
              </div>
              <div className="column is-9">
                <div className="content">
                <h3 className="title is-3">Workspace details</h3>
                <div className="box">
                    {
                      this.props.workspaces.status === 'succeeded' && <SuccessMessage message={WORKSPACE_DEPLOYMENT_SUCCESS} />
                    }
                    {
                      this.props.workspaces.status === 'failed' && <ErrorMessage errorMessage={this.props.workspaces.error || DEFAULT_NETWORK_ERROR} />
                    }
                  <div className="workspace-subhead mb-5">
                    <span>workspace: </span>{workspace.workspace}<br />
                    <span>workspace id: </span>{workspaceId}
                  </div>
                  <div className="workspace-section columns">
                    <div className="column is-6">
                      <div className="field is-grouped is-grouped-multiline">
                        <div className="control">
                          <div className="tags has-addons">
                            <span className="tag is-dark">flags</span>
                            <span className="tag is-success">{workspaceFlags.length}</span>
                          </div>
                        </div>
                        <div className="control">
                          <div className="tags has-addons">
                            <span className="tag is-dark">undeployed changes</span>
                            <span className="tag is-primary">{hasUndeployedChanges == true ? 'yes' : 'no'}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="column is-6">
                      <p>Deployed main config file:<br />
                        <a target="_blank" rel="noopener noreferrer" href={workspace.fullConfigFileUrl}>{workspace.fullConfigFileUrl}</a>
                      </p>
                      <p>Deployed fallback config file:<br />
                        <a target="_blank" rel="noopener noreferrer" href={workspace.fallbackConfigUrl}>{workspace.fallbackConfigUrl}</a>
                      </p>
                    </div>
                  </div>
                  <div className="section">
                    <h3 className="title is-4">Deployment</h3>
                    <div className="buttons">
                      {
                        showDiffEndpoint &&
                        <button className="button is-dark">
                          <a className="has-text-white" href={showDiffEndpoint} target="_blank" rel="noopener noreferrer">Local vs deployed diff</a>
                        </button>
                      }
                      <button className={`button is-success ${this.props.workspaces.status === 'pending' ? 'is-loading' : ''}`} onClick={() => this.handleDeployWorkspaceConfig(workspace.workspace)}>Deploy</button>
                    </div>
                  </div>
                  <div className="section mt-5">
                    <p><b>Replace with config from workspace:</b></p>
                    <div className="select">
                      <select onChange={this.handleSetWorkspaceConfig}>
                        <option key="default"></option>
                        {
                          uniqueWorkspaceNames.map((name: string) => {
                            return <option key={name}>{name}</option>
                          })
                        }
                      </select>
                    </div>
                    <button className={`button is-success ml-2 ${this.props.workspaces.status === 'pending' ? 'is-loading' : ''}`} onClick={this.handleUpdateWorkspaceConfig}>Update workspace</button>
                    <div className="notification is-warning is-light mt-3">
                      <button className="delete"></button>
                      Caution: Saving this will delete all current flags and replace them with the deployed config from the chosen workspace.
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

interface LinkStateProps {
  workspaces: IReducerDefaultStateBase<Workspace>
  flags: IReducerDefaultStateBase<Flag>
}

interface LinkDispatchProps {
  setWorkspaces: () => void
  setFlags: () => void
  deployWorkspaceConfig: (workspaceName: string) => void
  replaceWorkspaceConfig: (sourceWorkspaceId: string, destinationWorkspaceId: string) => void
  showWorkspaceDiff: (endpoint: string) => void
}

const mapStateToProps = (
  state: AppState,
  ownProps: WorkspaceDetailsProps
): LinkStateProps => ({
  workspaces: state.entities.workspaces,
  flags: state.entities.flags
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: WorkspaceDetailsProps
): LinkDispatchProps => ({
  setWorkspaces: bindActionCreators(setWorkspaces, dispatch),
  setFlags: bindActionCreators(setFlags, dispatch),
  deployWorkspaceConfig: bindActionCreators(deployWorkspaceConfig, dispatch),
  replaceWorkspaceConfig: bindActionCreators(replaceWorkspaceConfig, dispatch),
  showWorkspaceDiff: bindActionCreators(showWorkspaceDiff, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkspaceDetails)
