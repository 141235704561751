import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { AppState } from '../../../store/configureStore'
import { ThunkDispatch } from 'redux-thunk'
import { AppActions } from '../../../types/actions'
import { signIn } from '../../../actions/auth'
import { AuthState, AuthCredentials } from '../../../types/Auth' 
import { bindActionCreators } from 'redux'
import FormErrors from '../../FormErrors'
import Validate from '../../../util/formValidation'
import './index.css'

type SignInProps = {}

type Props = SignInProps & RouteComponentProps & LinkStateProps & LinkDispatchProps

type SignInState = {
  username: string
  password: string
  errors: any
}

class SignIn extends Component<Props, SignInState> {
  state = {
    username: "",
    password: "",
    errors: {
      blankfield: false,
      cognito: null
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        blankfield: false,
        cognito: null
      }
    })
  }

  handleSubmit = async (event: any) => {
    event.preventDefault()

    // Form validation
    this.clearErrorState()
    const error = Validate(event, this.state)
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      })
    }

    const credentails: AuthCredentials = {
      username: this.state.username,
      password: this.state.password
    }

    await this.props.signIn(credentails)

    if (this.props.auth.error) {
      this.setState({
        errors: { ...this.state.errors, cognito: this.props.auth.error }
      })
    } else {
      this.props.history.push('/workspaces')
    }
  }

  onInputChange = (event: any) => {
    // @ts-ignore
    this.setState({ [event.target.id]: event.target.value })
    document.getElementById(event.target.id)?.classList.remove("is-danger")
  }

  render() {
    return (
      <section className="section auth">
        <div className="container has-text-left">
          <h2>Log in</h2>
          <FormErrors formerrors={this.state.errors} />

          <form className="pt-3" onSubmit={this.handleSubmit}>
            <div className="field">
              <p className="control">
                <input 
                  className="input" 
                  type="text"
                  id="username"
                  aria-describedby="usernameHelp"
                  placeholder="Enter username or email"
                  value={this.state.username}
                  onChange={this.onInputChange}
                />
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left">
                <input 
                  className="input" 
                  type="password"
                  id="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.onInputChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-lock"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control">
                <a href="/forgotpassword">Forgot password?</a>
              </p>
            </div>
            <div className="field">
              <p className="control">
                <button className="button is-success">
                  Login
                </button>
              </p>
            </div>
          </form>
        </div>
      </section>
    )
  }
}

interface LinkStateProps {
  auth: AuthState
}

interface LinkDispatchProps {
  signIn: (credentials: AuthCredentials) => void
}

const mapStateToProps = (
  state: AppState,
  ownProps: SignInProps
): LinkStateProps => ({
  auth: state.entities.auth
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: SignInProps
): LinkDispatchProps => ({
  signIn: bindActionCreators(signIn, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn)