import React, { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTable, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table'
import { Flag, IFlagTableData } from '../../types/Flag'
import { Workspace } from '../../types/Workspace'
import matchSorter from 'match-sorter'
import './index.css'

type FlagsListTableProps = {
  flags: IFlagTableData[]
  selectedWorkspace: string
  setWorkspace: (event: any) => void
}

const FlagsListTable: React.FC<FlagsListTableProps> = (props) => {
  // custom filter UI for selecting
  // a unique option from a list
  function SelectColumnFilter({
    // @ts-ignore
    column: { filterValue, setFilter, preFilteredRows, id },
  }) {
    // calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
      const options = new Set()
      preFilteredRows.forEach((row: any) => {
        options.add(row.values[id])
      })
      // @ts-ignore
      return [...options.values()]
    }, [id, preFilteredRows])

    // render a multi-select box
    return (
      <div className="select">
        <select
          value={filterValue}
          onChange={e => {
            setFilter(e.target.value || undefined)
          }}
        >
          <option value="">All</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    )
  }

  const data = useMemo(
    () => props.flags,
    [props.flags]
  )

  const columns = useMemo(
    () => [
      {
        Header: 'name',
        accessor: 'name',
      },
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'in use',
        accessor: 'in_use',
        disableFilters: true
      },
      {
        Header: 'undeployed changes',
        accessor: 'undeployed_changes',
        disableFilters: true
      },
      {
        Header: 'default value',
        accessor: 'default_value',
        disableFilters: true
      },
      {
        Header: 'type',
        accessor: 'type',
        disableFilters: true
      },
      {
        Header: 'workspace',
        accessor: 'workspace',
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'includes'
      },
    ],
    []
  )

  // define a default UI for filtering
  // @ts-ignore
  function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter, }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce((value: any) => {
      setGlobalFilter(value || undefined)
    }, 200)

    return (
      <span>
        Search:{' '}
        <input
          className="input"
          value={value || ""}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${count} records...`}
          style={{
            fontSize: '1.1rem',
            border: '0',
          }}
        />
      </span>
    )
  }

  function fuzzyTextFilterFn(rows: any, id: any, filterValue: any) {
    return matchSorter(rows, filterValue, { keys: [(row: any) => row.values[id]] })
  }

  // let the table remove the filter if the string is empty
  fuzzyTextFilterFn.autoRemove = (val: any) => !val

  // define a default UI for filtering
  function DefaultColumnFilter({
    // @ts-ignore
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length

    return (
      <input
        className="input"
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    )
  }

  const filterTypes = React.useMemo(
    () => ({
      // add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // set up the default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const history = useHistory()

  const rowClickHandler = (id: string) => {
    history.push(`/flag/${id}`)
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  )

  return (
    props.flags && <div className="flags-list-container has-text-left">
      <table {...getTableProps()} className="table is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row)
            return (
              <tr onClick={() => rowClickHandler(row?.values?.id)} {...row.getRowProps()}>
                {row.cells.map((cell: any) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default FlagsListTable