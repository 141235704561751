import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Flag } from '../../types/Flag'
import { IReducerDefaultStateBase } from '../../types/reducers'
import { Workspace } from '../../types/Workspace'
import { AppState } from '../../store/configureStore'
import { ThunkDispatch } from 'redux-thunk'
import { AppActions } from '../../types/actions'
import { bindActionCreators } from 'redux'
import FlagsListTable from '../FlagsListTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.css'

type FlagsListProps = {
}

type Props = FlagsListProps & LinkStateProps & LinkDispatchProps

type FlagsListState = {
  workspace: string
  workspaceId: string
}

class FlagsList extends React.Component<Props, FlagsListState> {
  state: FlagsListState = {
    workspace: '',
    workspaceId: ''
  }

  componentDidMount = () => {
  }

  setWorkspace = (event: any) => {
    const workspaceName = event.target?.value
    const workspaceId = this.props.workspaces.allIds.filter((id: string) => {
      return this.props.workspaces.byId[id]?.workspace === workspaceName
    })
    this.setState({ workspace: workspaceName })
    this.setState({ workspaceId: workspaceId[0] })
  }
  
  render() {
    const addIcon = <FontAwesomeIcon icon={['fas', 'plus']} />
    
    const flagData = this.props.flags.byId
    const flagIds = this.props.flags.allIds
    const flags = flagIds.map((flagId: string) => flagData[flagId])

    const flagsTableData = flags.map((flag: Flag) => {
      const workspaceId = flag?.workspaceId || ''
      const workspaceName = this.props.workspaces.byId[workspaceId]?.workspace || ''
        
      return {
        name: flag.name,
        id: flag.id,
        in_use: JSON.stringify(flag.active) ? 'yes' : 'no',
        undeployed_changes: flag.hasUndeployedChange ? 'yes' : 'no',
        default_value: JSON.stringify(flag.defaultValue),
        type: flag.type,
        workspace: workspaceName,
        actions: 'delete'
      }
    })

    return (
      <div className="container flags has-background-light">
        <div className="section pt-5">
          <div className="has-text-left">
            <h2 className="flags-tile">Add a flag</h2>
            <p>Select workspace to add a flag.</p>
            <div className="columns mt-5">
              <div className="column is-2 ml-0 pl-0">
                <div className="select">
                  <select onChange={this.setWorkspace}>
                    <option></option>
                    {
                      this.props.workspaces.allIds.map((workspaceId: string) => {
                        return <option key={workspaceId}>{this.props.workspaces.byId[workspaceId].workspace}</option>
                      })
                    }
                  </select>
                </div>
              </div>
              <div className="column is-3 ml-0 pl-0">
                <Link to={`/workspace/${this.state.workspaceId}/createflag`}>
                  <button className={`button is-success`} disabled={this.state.workspaceId ? false : true}>
                    <span className="icon">
                      { addIcon }
                    </span>
                    <span>Add flag</span>
                  </button>
                </Link>
              </div>
              {/* <div className="column is-3 ml-0 pl-0">
                <h2>Workspace: <span className="nb">{this.state.workspace}</span></h2>
                <p><b>Id:</b> {this.state.workspaceId}</p>
              </div> */}
            </div>
          </div>
        </div>

        <div className="section pt-0 has-text-left">
          <h2 className="flags-tile mb-3">All flags</h2>
          <FlagsListTable flags={flagsTableData} setWorkspace={this.setWorkspace} selectedWorkspace={this.state.workspace} />
        </div>
      </div>
    )
  }
}

interface LinkStateProps {
  flags: IReducerDefaultStateBase<Flag>
  workspaces: IReducerDefaultStateBase<Workspace>
}

interface LinkDispatchProps {
  editFlag: (flag: Flag) => void
}

const mapStateToProps = (
  state: AppState,
  ownProps: FlagsListProps
): LinkStateProps => ({
  flags: state.entities.flags,
  workspaces: state.entities.workspaces
})

export default connect(
  mapStateToProps
)(FlagsList)