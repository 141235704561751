import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { Flag, Cohort } from '../../types/Flag'
import { Workspace } from '../../types/Workspace'
import { AppState } from '../../store/configureStore'
import { ThunkDispatch } from 'redux-thunk'
import { AppActions } from '../../types/actions'
import { editFlag, setFlags } from '../../actions/flags'
import { resetFlagsStatus } from '../../actions/flags'
import { IReducerDefaultStateBase } from '../../types/reducers'
import { bindActionCreators } from 'redux'
import './index.css'
import FlagCard from '../FlagCard'
import CohortDetails from '../CohortDetails'
import SuccessMessage from '../SuccessMessage'
import ErrorMessage from '../ErrorMessage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DEFAULT_NETWORK_ERROR, FLAG_EDIT_SUCCESS, EMPTY_FIELD_ERROR, ROLLOUT_VALUE_ERROR } from '../../config/constants'
import { validateForm } from '../../scripts/flagFormValidation'

type RouteParams = {
  id: string
}

interface FlagDetailsProps extends RouteComponentProps<RouteParams> {
}

export type FlagDetailsState = {
  isEditMode: boolean
  flag: Flag
  validationErrors: {
    blankfield: boolean
    rolloutPercentage: boolean
  }
}

type Props = FlagDetailsProps & LinkStateProps & LinkDispatchProps

class FlagDetails extends React.Component<Props, FlagDetailsState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isEditMode: false,
      flag: {
        id: '',
        flagId: '',
        name: '',
        cohorts: [],
        defaultValue: false,
        active: false,
        type: 'boolean',
        workspaceId: -1
      },
      validationErrors: {
        blankfield: false,
        rolloutPercentage: false
      }
    }
  }

  setFlagData = () => {
    const { id } = this.props.match.params
    const flag: Flag | undefined = this.props.flags.byId[id]
    if (flag) this.setState({ flag: flag })
  }

  editFlagCohort = (cohort: Cohort): void => {
    const { flag } = this.state
    const prevFlagCohorts = flag.cohorts ? flag.cohorts : []
    const prevFlagCohortsFiltered = prevFlagCohorts.filter((prevCohort: Cohort) => prevCohort.cohortId !== cohort.cohortId)
    this.setState({ flag: { ...flag, cohorts: [...prevFlagCohortsFiltered, cohort] }})
  }

  componentDidMount = () => {
    this.setFlagData()
  }

  public static getDerivedStateFromProps(nextProps: Props, prevState: FlagDetailsState): FlagDetailsState | null {
    window.scrollTo(0, 0)
    if ((typeof prevState.flag === "undefined") || nextProps.match.params.id !== prevState.flag.id) {
      const currentFlagId = nextProps.match.params.id
      const flag: Flag | undefined = nextProps.flags.byId[currentFlagId]

      const validationErrors = prevState.validationErrors ? prevState.validationErrors : {
        blankfield: false,
        rolloutPercentage: false
      }

      return {
        isEditMode: false,
        flag: flag,
        validationErrors: { ...validationErrors }
      }
    }
    // no flag change detected
    return null
 }

  editFlag = () => {
    this.setState({ isEditMode: true })
  }

  cancelEditFlag = () => {
    this.setState({ isEditMode: false })
  }

  addCohort = (event: any) => {
    event.preventDefault()
    const { flag } = this.state
    const prevFlagCohorts = flag.cohorts ? flag.cohorts : []
    const prevFlagCohortsSortdById = prevFlagCohorts?.sort((a: Cohort, b: Cohort) => (a.cohortId > b.cohortId) ? 1 : -1)
    const newCohortCriterionCriteriaId = prevFlagCohortsSortdById && prevFlagCohortsSortdById.length > 0 ? 
      prevFlagCohortsSortdById[prevFlagCohortsSortdById.length - 1].cohortId + 1 : 0
    const newCohort: Cohort = {
      cohortId: newCohortCriterionCriteriaId,
      cohortPriority: 0,
      stickinessProperty: 'ffUserId',
      rolloutValue: 0,
      cohortCriteria: []
    }
    const newCohorts = [...prevFlagCohorts, newCohort]
    this.setState({ flag: {...flag, cohorts: newCohorts } })
  }

  deleteCohort = (id: number) => {
    const { flag } = this.state
    const prevFlagCohorts = flag.cohorts ? flag.cohorts : []
    const prevFlagCohortsFiltered = prevFlagCohorts.filter((prevCohort: Cohort) => prevCohort.cohortId !== id)
    this.setState({ flag: { ...flag, cohorts: [...prevFlagCohortsFiltered] }, isEditMode: true })
  }

  toggleValidationError = (errorName: string | undefined): void => {
    let hasRolloutPercentageError = false
    if (errorName === 'rolloutPercentage') hasRolloutPercentageError = true
    this.setState({ validationErrors: { ...this.state.validationErrors, rolloutPercentage: hasRolloutPercentageError }})
  }

  onSetFlagID = (event: any) => this.setState({ flag: { ...this.state.flag, "id": event.target.value } })
  onSetFlagName = (event: any) => this.setState({ flag: { ...this.state.flag, "name": event.target.value } })
  onSetFlagDefaultValue = (event: any) => {
    const defaultValue = (event.target.value == 'true')
    this.setState({ flag: { ...this.state.flag, "defaultValue": defaultValue } })
  }
  onSetFlagType = (event: any) => this.setState({ flag: { ...this.state.flag, "type": event.target.value } })
  onSetFlagActive = (event: any) => {
    this.setState({ flag: { ...this.state.flag, "active": event.target.checked } })
  }

  submitEditFlag = async (flag: Flag, event: any) => {
    event.preventDefault()
    // validate user input
    const validationResults = validateForm(this.state)
    if (validationResults.errors.blankField) { this.setState({ validationErrors: { ...this.state.validationErrors, blankfield: true }}) }
    if (validationResults.errors.rolloutPercentage) { this.setState({ validationErrors: { ...this.state.validationErrors, rolloutPercentage: true }}) }
    if (validationResults.isValid) {
      await this.props.editFlag(flag)
      this.setState({ isEditMode: false })
      await this.props.setFlags()
    }
  }

  render() {
    // const { id } = this.props.match.params
    const { flag, isEditMode } = this.state
    const flagName = flag && flag.name ? flag.name : ''
    const cohortAlphaSort = (a: Cohort, b: Cohort) => (a.cohortId > b.cohortId) ? 1 : -1
    const workspace: Workspace | undefined = flag && flag.workspaceId ? this.props.workspaces.byId[flag.workspaceId] : undefined
    const workspaceName = workspace && workspace.workspace ? workspace.workspace : ''
    const workspaceId = workspace ? workspace.id : ''
    const workspaceFlags = Object.values(this.props.flags.byId).filter((flag: Flag) => flag.workspaceId?.toString() === workspaceId.toString())
    const addIcon = <FontAwesomeIcon icon={['fas', 'plus']} />
    const leftArrowIcon = <FontAwesomeIcon icon={['fas', 'angle-double-left']} />
    const flagReadOnly = <>
      <div className="columns">
        <div className="column is-9">
          <div className="columns">
            <div className="column is-6">

              <div className="field">
                <label className="label">Flag name</label>
                <div className="control">
                  <input className="input" type="text" value={flag && flag.name ? flag.name : ''} readOnly />
                </div>
              </div>

              <div className="field">
                <label className="label">Flag ID</label>
                <div className="control">
                  <input disabled className="input" type="text" value={flag && flag.id ? flag.id : ''} readOnly />
                </div>
              </div>

              <div className="field">
                <label className="label">Flag type</label>
                <div className="control">
                  <input className="input" type="text" value={flag && flag.type ? flag.type : ''} readOnly />
                </div>
              </div>

            </div>
            <div className="column is-6">

              <div className="field">
                <div className="control">
                  <label className="checkbox">
                    <input type="checkbox" checked={flag && flag.active} readOnly />
                    In use
                  </label>
                </div>
              </div>
                
              <div className="field">
                <label className="label">Flag default value</label>
                <div className="control">
                  <input className="input" type="text" value={flag && flag.defaultValue !== undefined ? flag.defaultValue.toString() : ''} readOnly />
                </div>
              </div>

            </div>

          </div>

        </div>
        <div className="column is-3 has-text-right">
          <button onClick={this.editFlag} className={`button is-success ${this.props.flags.status === 'pending' ? 'is-loading' : ''}`}>Edit flag</button>
          <div className="mt-3">
            {
              this.props.flags.status === 'succeeded' && <SuccessMessage message={FLAG_EDIT_SUCCESS} />
            }
            {
              this.props.flags.status === 'failed' && <ErrorMessage errorMessage={this.props.flags.error || DEFAULT_NETWORK_ERROR} />
            }
          </div>
        </div>
      </div>
      <div className="mt-5">
        {
          flag && flag.cohorts && flag.cohorts.sort(cohortAlphaSort).map((cohort: any) => {
            return <CohortDetails 
              key={`cohort-edit-${cohort.cohortId}`} 
              cohort={cohort} 
              editCohort={this.editFlagCohort} 
              deleteCohort={this.deleteCohort}
              isEditMode={this.state.isEditMode}
              toggleValidationError={this.toggleValidationError}
            />
          })
        }
      </div>
    </>

    const editFlagForm = <form onSubmit={event => this.submitEditFlag(this.state.flag, event)}>
      <div className="columns">
        <div className="column is-9">
          <div className="columns">
            <div className="column is-6">

              <div className="field">
                <label className="label">Flag name</label>
                <div className="control">
                  <input className={`input ${this.state.validationErrors.blankfield && !this.state.flag.name ? 'is-danger' : ''}`} type="text" onChange={this.onSetFlagName} defaultValue={flag && flag.name ? flag.name : ''} placeholder="ex. My Flag" />
                </div>
              </div>

              <div className="field">
                <label className="label">Flag ID</label>
                <div className="control">
                  <input disabled className={`input ${this.state.validationErrors.blankfield && !this.state.flag.flagId ? 'is-danger' : ''}`} type="text" value={flag && flag.id ? flag.id : ''} readOnly />
                </div>
              </div>

              <div className="field">
                <label className="label">Flag type</label>
                <div className="control">
                  <div className="select">
                    <select onChange={this.onSetFlagType} defaultValue={flag && flag.defaultValue ? flag.defaultValue.toString() : 'false'}>
                      <option>boolean</option>
                      <option>string</option>
                    </select>
                  </div>
                </div>
              </div>

            </div>
            <div className="column is-6">

              <div className="field">
                <div className="control">
                  <label className="checkbox">
                    <input type="checkbox" onChange={this.onSetFlagActive} defaultChecked={flag && flag.active} />
                    In use
                  </label>
                </div>
              </div>
                
              <div className="field">
                <label className="label">Flag default value</label>
                <div className="control">
                  <div className="select">
                    <select onChange={this.onSetFlagDefaultValue} defaultValue={flag && flag.defaultValue ? flag.defaultValue.toString() : 'false'}>
                      <option>true</option>
                      <option>false</option>
                    </select>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div className="column is-3 has-text-right">
          <button className="button is-success">Save flag</button>
          <button onClick={this.cancelEditFlag} className="button is-light ml-3">Cancel</button>
          <div className="mt-3">
            {
              this.state.validationErrors.blankfield && (!this.state.flag.name || !this.state.flag.flagId) && <ErrorMessage errorMessage={EMPTY_FIELD_ERROR} />
            }
            {
              this.state.validationErrors.rolloutPercentage && <ErrorMessage errorMessage={ROLLOUT_VALUE_ERROR} />
            }
          </div>
        </div>
      </div>
      <div className="mt-5">
        {
          flag && flag.cohorts && flag.cohorts.sort(cohortAlphaSort).map((cohort: any) => {
            return <CohortDetails 
              key={`cohort-edit-${cohort.cohortId}`} 
              cohort={cohort} 
              editCohort={this.editFlagCohort}
              deleteCohort={this.deleteCohort}
              isEditMode={this.state.isEditMode}
              toggleValidationError={this.toggleValidationError}
            />
          })
        }
      </div>
      <div className="mt-5">
        <button className="button is-success" onClick={event => this.addCohort(event)}>
          <span className="icon">
            { addIcon }
          </span>
          <span>Add cohort</span>
        </button>
      </div>
      
    </form>

    const flagDetails = isEditMode ? editFlagForm : flagReadOnly

    return (
      <div className="container flag-details has-background-light">
        <div className="section">
          <div className="columns">
            <div className="column is-3">
              <div className="has-text-left mb-5">
                <h2><span>workspace: </span>{workspaceName}</h2>
                <h2><span>workspace id: </span>{workspaceId}</h2>
              </div>
              <Link to={`/workspace/${workspaceId}/createflag`}>
                <button className="button is-success">
                  <span className="icon">
                    { addIcon }
                  </span>
                  <span>Add flag</span>
                </button>
              </Link>
              <div className="tile is-ancestor mt-3">
                <div className="tile is-vertical is-parent">
                  {
                    workspaceFlags.map((flag: Flag) => {
                      return <FlagCard key={`flag-card-${flag.id}`} flag={flag} resetFlagsStatus={this.props.resetFlagsStatus} />
                     })
                  }
                </div>
              </div>
            </div>
            <div className="column is-9">
              <div className="content">
                <div className="columns">
                  <div className="column">
                    <h3 className="title">Flag details</h3>
                  </div>
                  <div className="column has-text-right">
                    <Link to={`/workspace/${workspaceId}`}>
                      <button className="button is-dark">
                        <span className="icon">
                          { leftArrowIcon }
                        </span>
                        <span>Back to workspace</span>
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="box">
                  { flagDetails }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

interface LinkStateProps {
  flags: IReducerDefaultStateBase<Flag>
  workspaces: IReducerDefaultStateBase<Workspace>
}

interface LinkDispatchProps {
  editFlag: (flag: Flag) => void
  resetFlagsStatus: () => void
  setFlags: () => void
}

const mapStateToProps = (
  state: AppState,
  ownProps: FlagDetailsProps
): LinkStateProps => ({
  flags: state.entities.flags,
  workspaces: state.entities.workspaces
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: FlagDetailsProps
): LinkDispatchProps => ({
  editFlag: bindActionCreators(editFlag, dispatch),
  resetFlagsStatus: bindActionCreators(resetFlagsStatus, dispatch),
  setFlags: bindActionCreators(setFlags, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FlagDetails)