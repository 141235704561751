import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { AppState } from '../../store/configureStore'
import { ThunkDispatch } from 'redux-thunk'
import { showWorkspaceDiff } from '../../actions/workspaces'
import { Workspace } from '../../types/Workspace'
import { IReducerDefaultStateBase } from '../../types/reducers'
import { AppActions } from '../../types/actions'
import { bindActionCreators } from 'redux'
import './index.css'

type WorkspaceDiffProps = {
}

type Props = WorkspaceDiffProps & LinkStateProps

class WorkspaceDiff extends Component<Props> {
  render() {
    console.log(`HTML :::: ${this.props.workspaces.diffHTML}`)
    const htmlRemoveNewlines = this.props.workspaces.diffHTML?.replace('\n', '')
    const htmlRemoveEscapes = htmlRemoveNewlines?.replace('\\', '')
    const diffHTML = `<div>${JSON.stringify(htmlRemoveEscapes)}</div>`
    return (
      <div className="container workspace-diff has-background-light">
        <h2>Workspace diff:</h2>
        <section>
          {diffHTML}
        </section>
      </div>
    )
  }

}

interface LinkStateProps {
  workspaces: IReducerDefaultStateBase<Workspace>
}

const mapStateToProps = (
  state: AppState,
  ownProps: WorkspaceDiffProps
): LinkStateProps => ({
  workspaces: state.entities.workspaces
})

export default connect(
  mapStateToProps
)(WorkspaceDiff)
  