import React from 'react'
import './index.css'

export default function Welcome() {
  return (
    <section className="section auth hero">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">
            Welcome!
          </h1>
          <h2 className="subtitle pt-3">
            <p>You have successfully registered a new account.</p>
            <p>We've sent you a email. Please click on the confirmation link to verify your account.</p>
          </h2>
        </div>
      </div>
    </section>
  )
}