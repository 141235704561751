import React from 'react'
import 'bulma/css/bulma.css'
import './App.css'
import { Provider } from 'react-redux'
import { store } from './store/configureStore'
import RootContainer from './components/RootContainer'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes, faPlus, faLock, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'
import { faMinusSquare } from '@fortawesome/free-regular-svg-icons'

library.add(faLock, faTimes, faPlus, faMinusSquare, faAngleDoubleLeft)

const App: React.FC = () => {
  return (
    <div className="App">
      <Provider store={store}>
        <RootContainer />
      </Provider>
    </div>
  )
}

export default App
