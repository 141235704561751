import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import Amplify from 'aws-amplify'
import config from './config/config'
import * as serviceWorker from './serviceWorker'
//   _____     ______     ______     __  __        __         ______     __  __     __   __     ______     __  __     __         __  __             
//  /\  __-.  /\  __ \   /\  == \   /\ \/ /       /\ \       /\  __ \   /\ \/\ \   /\ "-.\ \   /\  ___\   /\ \_\ \   /\ \       /\ \_\ \   
//  \ \ \/\ \ \ \  __ \  \ \  __<   \ \  _"-.     \ \ \____  \ \  __ \  \ \ \_\ \  \ \ \-.  \  \ \ \____  \ \  __ \  \ \ \____  \ \____ \    
//   \ \____-  \ \_\ \_\  \ \_\ \_\  \ \_\ \_\     \ \_____\  \ \_\ \_\  \ \_____\  \ \_\\"\_\  \ \_____\  \ \_\ \_\  \ \_____\  \/\_____\ 
//    \/____/   \/_/\/_/   \/_/ /_/   \/_/\/_/      \/_____/   \/_/\/_/   \/_____/   \/_/ \/_/   \/_____/   \/_/\/_/   \/_____/   \/_____/ 

//        !
//        !
//        ^
//       / \
//      /___\
//     |=   =|
//     |     |
//     |     |
//     |     |
//     |     |
//     |     |
//     |     |
//     |     |
//     |     |
//     |     |
//    /|##!##|\
//   / |##&##| \
//  /  |##!##|  \
// |  / ^ | ^ \  |
// | /  ( | )  \ |
// |/   ( | )   \|
//     (( | ))
//    (B()|()g)
//    (B()()bs)
//     (( | ))
//      (( ))
//       ( )
//        .
//        .
//        .

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  }
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
