import React from 'react'
import WorkspaceCard from '../WorkspaceCard'
import { connect } from 'react-redux'
import { setWorkspaces } from '../../actions/workspaces'
import { setFlags } from '../../actions/flags'
import { Workspace } from '../../types/Workspace'
import { Flag } from '../../types/Flag'
import { AppState } from '../../store/configureStore'
import { ThunkDispatch } from 'redux-thunk'
import { AppActions } from '../../types/actions'
import { IReducerDefaultStateBase } from '../../types/reducers'
import { bindActionCreators } from 'redux'
import './index.css'

type WorkspacesProps = {
}

type WorkspacesState = {
}

type Props = WorkspacesProps & LinkStateProps

class Workspaces extends React.Component<Props, WorkspacesState> {
  state: WorkspacesState = {
  }

  componentDidMount = () => {
  }

  render() {
    return (
      <div className="container workspaces">
        <h2 className="workspaces-title">Workspaces</h2>
        <div className="section">
          <div className="row columns is-multiline">
            {
              this.props.workspaces.allIds.map((workspaceId: string) => {
                const workspaceFlags = this.props.flags.allIds.filter((flagId: string) => {
                  return this.props.flags.byId[flagId] && 
                    this.props.flags.byId[flagId].workspaceId && 
                    this.props.flags.byId[flagId].workspaceId?.toString() === workspaceId
                })

                const hasUndeployedChanges = workspaceFlags.some((flagId: string) => {
                  return this.props.flags.byId[flagId].hasUndeployedChange
                })

                return <WorkspaceCard 
                  key={workspaceId} 
                  workspace={this.props.workspaces.byId[workspaceId]} 
                  flagQty={workspaceFlags.length} 
                  hasUndeployedChanges={hasUndeployedChanges}
                />
              })
            }
          </div>
        </div>
      </div>
    )
  }

}

interface LinkStateProps {
  workspaces: IReducerDefaultStateBase<Workspace>
  flags: IReducerDefaultStateBase<Flag>
}

const mapStateToProps = (
  state: AppState,
  ownProps: WorkspacesProps
): LinkStateProps => ({
  workspaces: state.entities.workspaces,
  flags: state.entities.flags
})

export default connect(
  mapStateToProps
)(Workspaces)
