import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { Flag, ICreateFlag, Cohort } from '../../types/Flag'
import { Workspace } from '../../types/Workspace'
import { AppState } from '../../store/configureStore'
import { ThunkDispatch } from 'redux-thunk'
import { AppActions } from '../../types/actions'
import { addFlag } from '../../actions/flags'
import { IReducerDefaultStateBase } from '../../types/reducers'
import { bindActionCreators } from 'redux'
import './index.css'
import FlagCard from '../FlagCard'
import SuccessMessage from '../SuccessMessage'
import ErrorMessage from '../ErrorMessage'
import CohortDetails from '../CohortDetails'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DEFAULT_NETWORK_ERROR, FLAG_CREATION_SUCCESS, EMPTY_FIELD_ERROR, ROLLOUT_VALUE_ERROR } from '../../config/constants'
import { validateForm } from '../../scripts/flagFormValidation'

type RouteParams = {
  id: string
}

interface CreateFlagProps extends RouteComponentProps<RouteParams> {
}

export type CreateFlagState = {
  flag: ICreateFlag;
  validationErrors: {
    blankfield: boolean
    rolloutPercentage: boolean
  }
}

type Props = CreateFlagProps & LinkStateProps & LinkDispatchProps

class CreateFlag extends React.Component<Props, CreateFlagState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      flag: {
        flagId: '',
        name: '',
        cohorts: [],
        defaultValue: false,
        active: false,
        type: 'boolean',
        workspaceId: -1
      },
      validationErrors: {
        blankfield: false,
        rolloutPercentage: false
      }
    }
  }

  componentDidMount = () => {
    const workspaceId = this.props.match.params.id || '-1'
    this.setState({ flag: { ...this.state.flag, workspaceId: parseInt(workspaceId) } })
  }

  editFlagCohort = (cohort: Cohort): void => {
    const { flag } = this.state
    const prevFlagCohorts = flag.cohorts ? flag.cohorts : []
    const prevFlagCohortsFiltered = prevFlagCohorts.filter((prevCohort: Cohort) => prevCohort.cohortId !== cohort.cohortId)
    this.setState({ flag: { ...flag, cohorts: [...prevFlagCohortsFiltered, cohort] }})
  }

  addCohort = (event: any) => {
    event.preventDefault()
    const { flag } = this.state
    const prevFlagCohorts = flag.cohorts ? flag.cohorts : []
    const prevFlagCohortsSortdById = prevFlagCohorts?.sort((a: Cohort, b: Cohort) => (a.cohortId > b.cohortId) ? 1 : -1)
    const newCohortCriterionCriteriaId = prevFlagCohortsSortdById && prevFlagCohortsSortdById.length > 0 ? 
      prevFlagCohortsSortdById[prevFlagCohortsSortdById.length - 1].cohortId + 1 : 0
    const newCohort: Cohort = {
      cohortId: newCohortCriterionCriteriaId,
      cohortPriority: 0,
      stickinessProperty: 'ffUserId',
      rolloutValue: 0,
      cohortCriteria: []
    }
    const newCohorts = [...prevFlagCohorts, newCohort]
    this.setState({ flag: {...flag, cohorts: newCohorts } })
  }

  deleteCohort = (id: number) => {
    const { flag } = this.state
    const prevFlagCohorts = flag.cohorts ? flag.cohorts : []
    const prevFlagCohortsFiltered = prevFlagCohorts.filter((prevCohort: Cohort) => prevCohort.cohortId !== id)
    this.setState({ flag: { ...flag, cohorts: [...prevFlagCohortsFiltered] } })
  }

  toggleValidationError = (errorName: string | undefined): void => {
    let hasRolloutPercentageError = false
    if (errorName === 'rolloutPercentage') hasRolloutPercentageError = true
    this.setState({ validationErrors: { ...this.state.validationErrors, rolloutPercentage: hasRolloutPercentageError }})
  }

  onSetFlagID = (event: any) => this.setState({ flag: { ...this.state.flag, "flagId": event.target.value } })
  onSetFlagName = (event: any) => this.setState({ flag: { ...this.state.flag, "name": event.target.value } })
  onSetFlagDefaultValue = (event: any) => {
    const defaultValue = (event.target.value == 'true')
    this.setState({ flag: { ...this.state.flag, "defaultValue": defaultValue } })
  }
  onSetFlagType = (event: any) => this.setState({ flag: { ...this.state.flag, "type": event.target.value } })
  onSetFlagActive = (event: any) => this.setState({ flag: { ...this.state.flag, "active": event.target.checked } })

  submitCreateFlag = (flag: ICreateFlag, event: any) => {
    event.preventDefault()
    // validate user input
    const validationResults = validateForm(this.state)
    if (validationResults.errors.blankField) { this.setState({ validationErrors: { ...this.state.validationErrors, blankfield: true }}) }
    if (validationResults.errors.rolloutPercentage) { this.setState({ validationErrors: { ...this.state.validationErrors, rolloutPercentage: true }}) }
    // create flag
    if (validationResults.isValid) this.props.addFlag(flag)
  }

  render() {
    const { flag } = this.state
    const flagName = flag && flag.name ? flag.name : ''
    const cohortAlphaSort = (a: Cohort, b: Cohort) => (a.cohortId > b.cohortId) ? 1 : -1
    const workspace: Workspace | undefined = flag && flag.workspaceId ? this.props.workspaces.byId[flag.workspaceId] : undefined
    const workspaceName = workspace && workspace.workspace ? workspace.workspace : ''
    const workspaceId = this.state.flag.workspaceId ? this.state.flag.workspaceId : '-1'
    const workspaceFlags = Object.values(this.props.flags.byId).filter((flag: Flag) => flag.workspaceId?.toString() === workspaceId.toString())
    const addIcon = <FontAwesomeIcon icon={['fas', 'plus']} />
    
    return (
      <div className="container has-background-light">
        <div className="section">
          <div className="columns">
            <div className="column is-3">
            <div className="has-text-left">
              <h2>{workspaceName}</h2>
                <p>ID: {workspaceId}</p>
              </div>
              <Link to={`/workspace/${workspaceId}/createflag`}>
                <button className="button is-success">
                  <span className="icon">
                    { addIcon }
                  </span>
                  <span>Add flag</span>
                </button>
              </Link>
              <div className="tile is-ancestor mt-3">
                <div className="tile is-vertical is-parent">
                  {
                    workspaceFlags.map((flag: Flag) => {
                      return <FlagCard key={`flag-card-${flag.id}`} flag={flag} />
                     })
                  }
                </div>
              </div>
            </div>
            <div className="column is-9">
              <div className="content">
                <h3 className="title">Create a new flag</h3>
                <div className="box">
                <h3 className="title is-5">{flagName}</h3>
                  <form onSubmit={event => this.submitCreateFlag(this.state.flag, event)}>
                    <div className="columns">
                      <div className="column is-9">
                        <div className="columns">
                          <div className="column is-6">

                            <div className="field">
                              <label className="label">Flag name</label>
                              <div className="control">
                                <input className={`input ${this.state.validationErrors.blankfield && !this.state.flag.name ? 'is-danger' : ''}`} type="text" onChange={this.onSetFlagName} defaultValue={flag && flag.name ? flag.name : ''} placeholder="ex. My Flag" />
                              </div>
                            </div>

                            <div className="field">
                              <label className="label">Flag ID</label>
                              <div className="control">
                                <input className={`input ${this.state.validationErrors.blankfield && !this.state.flag.flagId ? 'is-danger' : ''}`} type="text" defaultValue={flag && flag.flagId ? flag.flagId : ''} onChange={this.onSetFlagID} />
                              </div>
                            </div>

                            <div className="field">
                              <label className="label">Flag type</label>
                              <div className="control">
                                <div className="select">
                                  <select onChange={this.onSetFlagType} defaultValue={flag && flag.defaultValue ? flag.defaultValue.toString() : 'false'}>
                                    <option>boolean</option>
                                    <option>string</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="column is-6">

                            <div className="field">
                              <div className="control">
                                <label className="checkbox">
                                  <input type="checkbox" onChange={this.onSetFlagActive} checked={flag && flag.active} />
                                  In use
                                </label>
                              </div>
                            </div>
                              
                            <div className="field">
                              <label className="label">Flag default value</label>
                              <div className="control">
                                <div className="select">
                                  <select onChange={this.onSetFlagDefaultValue} defaultValue={flag && flag.defaultValue ? flag.defaultValue.toString() : 'false'}>
                                    <option>true</option>
                                    <option>false</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                          </div>

                        </div>

                      </div>
                      <div className="column is-3 has-text-right">
                        <button className={`button is-success ${this.props.flags.status === 'pending' ? 'is-loading' : ''}`}>Create flag</button>
                        <div className="mt-3">
                          {
                            this.props.flags.status === 'succeeded' && <SuccessMessage message={FLAG_CREATION_SUCCESS} />
                          }
                          {
                            this.props.flags.status === 'failed' && <ErrorMessage errorMessage={this.props.flags.error || DEFAULT_NETWORK_ERROR} />
                          }
                          {
                            this.state.validationErrors.blankfield && (!this.state.flag.name || !this.state.flag.flagId) && <ErrorMessage errorMessage={EMPTY_FIELD_ERROR} />
                          }
                          {
                            this.state.validationErrors.rolloutPercentage && <ErrorMessage errorMessage={ROLLOUT_VALUE_ERROR} />
                          }
                        </div>
                      </div>
                    </div>
                    <div className="mt-5">
                      {
                        flag && flag.cohorts && flag.cohorts.sort(cohortAlphaSort).map((cohort: any) => {
                          return <CohortDetails 
                            key={`cohort-edit-${cohort.cohortId}`} 
                            cohort={cohort} 
                            editCohort={this.editFlagCohort}
                            deleteCohort={this.deleteCohort}
                            isEditMode={true}
                            toggleValidationError={this.toggleValidationError}
                          />
                        })
                      }
                    </div>
                    <div className="mt-5">
                      <button className="button is-success" onClick={event => this.addCohort(event)}>
                        <span className="icon">
                          { addIcon }
                        </span>
                        <span>Add cohort</span>
                      </button>
                    </div>
                    
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

interface LinkStateProps {
  flags: IReducerDefaultStateBase<Flag>
  workspaces: IReducerDefaultStateBase<Workspace>
}

interface LinkDispatchProps {
  addFlag: (flag: ICreateFlag) => void
}

const mapStateToProps = (
  state: AppState,
  ownProps: CreateFlagProps
): LinkStateProps => ({
  flags: state.entities.flags,
  workspaces: state.entities.workspaces
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: CreateFlagProps
): LinkDispatchProps => ({
  addFlag: bindActionCreators(addFlag, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateFlag)