import { Flag, ICreateFlag } from './Flag'
import { Workspace } from './Workspace'

export const SET_FLAGS_START = 'SET_FLAGS_START'
export const SET_FLAGS_SUCCESS = 'SET_FLAGS_SUCCESS'
export const SET_FLAGS_ERROR = 'SET_FLAGS_ERROR'
export const ADD_FLAG = 'ADD_FLAG'
export const ADD_FLAG_START = 'ADD_FLAG_START'
export const ADD_FLAG_SUCCESS = 'ADD_FLAG_SUCCESS'
export const ADD_FLAG_ERROR = 'ADD_FLAG_ERROR'
export const EDIT_FLAG = 'EDIT_FLAG'
export const EDIT_FLAG_START = 'EDIT_FLAG_START'
export const EDIT_FLAG_SUCCESS = 'EDIT_FLAG_SUCCESS'
export const EDIT_FLAG_ERROR = 'EDIT_FLAG_ERROR'
export const DELETE_FLAG = 'DELETE_FLAG'

export const SET_WORKSPACES_START = 'SET_WORKSPACES_START'
export const SET_WORKSPACES_SUCCESS = 'SET_WORKSPACES_SUCCESS'
export const SET_WORKSPACES_ERROR = 'SET_WORKSPACES_ERROR'
export const DEPLOY_WORKSPACE_CONFIG_START = 'DEPLOY_WORKSPACE_CONFIG_START'
export const DEPLOY_WORKSPACE_CONFIG_SUCCESS = 'DEPLOY_WORKSPACE_CONFIG_SUCCESS'
export const DEPLOY_WORKSPACE_CONFIG_FAIL = 'DEPLOY_WORKSPACE_CONFIG_FAIL'
export const REPLACE_WORKSPACE_CONFIG_START = 'REPLACE_WORKSPACE_CONFIG_START'
export const REPLACE_WORKSPACE_CONFIG_SUCCESS = 'REPLACE_WORKSPACE_CONFIG_SUCCESS'
export const REPLACE_WORKSPACE_CONFIG_FAIL = 'REPLACE_WORKSPACE_CONFIG_FAIL'
export const SHOW_WORKSPACE_DIFF_START = 'SHOW_WORKSPACE_DIFF_START'

export const SIGNIN_START = 'SIGNIN_START'
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS'
export const SIGNIN_ERROR = 'SIGNIN_ERROR'

export const SIGNOUT_START = 'SIGNOUT_START'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNOUT_ERROR = 'SIGNOUT_ERROR'

export const SET_AUTH_STATUS_START = 'SET_AUTH_STATUS_START'
export const SET_USER_START = 'SET_USER_START'
export const RESET_FLAGS_STATUS_START = 'RESET_FLAGS_STATUS_START'
export const SHOW_WORKSPACE_DIFF_SUCCESS = 'SHOW_WORKSPACE_DIFF_SUCCESS'
export const SHOW_WORKSPACE_DIFF_FAIL = 'SHOW_WORKSPACE_DIFF_FAIL'

export interface SetFlagsStartAction {
  type: typeof SET_FLAGS_START;
}

export interface SetFlagsSuccessAction {
  type: typeof SET_FLAGS_SUCCESS;
  flags: Flag[];
}

export interface SetFlagsErrorAction {
  type: typeof SET_FLAGS_ERROR;
  error: string;
}

export interface AddFlagStartAction {
  type: typeof ADD_FLAG_START;
}

export interface AddFlagSuccessAction {
  type: typeof ADD_FLAG_SUCCESS;
  flag: Flag;
}

export interface AddFlagErrorAction {
  type: typeof ADD_FLAG_ERROR;
  error: string;
}

export interface EditFlagAction {
  type: typeof EDIT_FLAG;
  flag: Flag;
}

export interface EditFlagStartAction {
  type: typeof EDIT_FLAG_START;
}

export interface EditFlagSuccessAction {
  type: typeof EDIT_FLAG_SUCCESS;
  flag: Flag;
}

export interface EditFlagErrorAction {
  type: typeof EDIT_FLAG_ERROR;
  error: string;
}

export interface DeleteFlagAction {
  type: typeof DELETE_FLAG;
  id: string;
}

export interface setWorkspacesStartAction {
  type: typeof SET_WORKSPACES_START;
}

export interface setWorkspacesSuccessAction {
  type: typeof SET_WORKSPACES_SUCCESS;
  workspaces: Workspace[];
}

export interface setWorkspacesErrorAction {
  type: typeof SET_WORKSPACES_ERROR;
  error: string;
}

export interface deployWorkspaceConfigStart {
  type: typeof DEPLOY_WORKSPACE_CONFIG_START;
}

export interface deployWorkspaceConfigSuccess {
  type: typeof DEPLOY_WORKSPACE_CONFIG_SUCCESS;
}

export interface deployWorkspaceConfigFail {
  type: typeof DEPLOY_WORKSPACE_CONFIG_FAIL;
  error: string;
}

export interface replaceWorkspaceConfigStart {
  type: typeof REPLACE_WORKSPACE_CONFIG_START;
}

export interface replaceWorkspaceConfigSuccess {
  type: typeof REPLACE_WORKSPACE_CONFIG_SUCCESS;
}

export interface replaceWorkspaceConfigFail {
  type: typeof REPLACE_WORKSPACE_CONFIG_FAIL;
  error: string;
}

export interface signInStartAction {
  type: typeof SIGNIN_START;
}

export interface signInSuccessAction {
  type: typeof SIGNIN_SUCCESS;
  user: any;
}

export interface signInErrorAction {
  type: typeof SIGNIN_ERROR;
  error: string;
}

export interface signOutStartAction {
  type: typeof SIGNOUT_START;
}

export interface signOutSuccessAction {
  type: typeof SIGNOUT_SUCCESS;
}

export interface signOutErrorAction {
  type: typeof SIGNOUT_ERROR;
  error: string;
}

export interface setAuthStatusStart {
  type: typeof SET_AUTH_STATUS_START;
  isAuthorized: boolean;
}

export interface setUserStart {
  type: typeof SET_USER_START;
  user: any;
}

export interface resetFlagsStatusStartAction {
  type: typeof RESET_FLAGS_STATUS_START;
}

export interface showWorkspaceDiffStartAction {
  type: typeof SHOW_WORKSPACE_DIFF_START;
}

export interface showWorkspaceDiffSuccess {
  type: typeof SHOW_WORKSPACE_DIFF_SUCCESS;
  html: string;
}

export interface showWorkspaceDiffFailAction {
  type: typeof SHOW_WORKSPACE_DIFF_FAIL;
  error: string;
}

export type AuthActionTypes = setAuthStatusStart | setUserStart | signInStartAction | signInSuccessAction | signInErrorAction | signOutStartAction | signOutSuccessAction | signOutErrorAction

export type FlagActionTypes = SetFlagsStartAction | SetFlagsSuccessAction | SetFlagsErrorAction | AddFlagStartAction | AddFlagSuccessAction | AddFlagErrorAction | EditFlagStartAction | EditFlagSuccessAction | EditFlagErrorAction | DeleteFlagAction | resetFlagsStatusStartAction

export type WorkspaceActionTypes = setWorkspacesStartAction | setWorkspacesSuccessAction | setWorkspacesErrorAction | deployWorkspaceConfigStart | deployWorkspaceConfigSuccess | deployWorkspaceConfigFail | replaceWorkspaceConfigStart | replaceWorkspaceConfigSuccess | replaceWorkspaceConfigFail | showWorkspaceDiffStartAction | showWorkspaceDiffFailAction | showWorkspaceDiffSuccess

export type AppActions = AuthActionTypes | FlagActionTypes | WorkspaceActionTypes