import React from 'react'
import './index.css'
import { Cohort, ICohortCriterion } from '../../types/Flag'
import CohortCriterion from '../CohortCriterion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type CohortDetailsProps = {
  cohort: Cohort;
  isEditMode: boolean;
  editCohort: (cohort: Cohort) => void;
  deleteCohort: (cohortId: number, event: any) => void;
  toggleValidationError: (errorName: string | undefined) => void;
}

type CohortDetailsState = {
  cohort: Cohort
  validationErrors: {
    rolloutPercentage: boolean
  }
}

class CohortDetails extends React.Component<CohortDetailsProps, CohortDetailsState> {
  constructor(props: CohortDetailsProps) {
    super(props)
    this.state = {
      cohort: {
        cohortId: 0,
        cohortPriority: 0,
        rolloutValue: 0,
        stickinessProperty: '',
        cohortCriteria: []
      },
      validationErrors: {
        rolloutPercentage: false
      }
    }
  }

  componentDidMount = () => {
    const { cohort } = this.props
    this.setCohortData(cohort)
  }

  validateForm = (rolloutValue: string) => {
    const globalRegex = RegExp(/(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,})?$)/, 'g')
    const result = globalRegex.test(rolloutValue)
    return result
  }

  editCohortCriterion = ((cohortCriterion: ICohortCriterion) => {
    const { cohort } = this.state
    const prevCohortCriteria = cohort.cohortCriteria && cohort.cohortCriteria.length > 0 ? 
      cohort.cohortCriteria.filter((prevCohortCriterion: ICohortCriterion) => prevCohortCriterion.cohortCriteriaId !== cohortCriterion.cohortCriteriaId) :
      []
    const newCohortCriteria = [...prevCohortCriteria, cohortCriterion]
    const newCohort = { ...cohort, cohortCriteria: newCohortCriteria }
    this.setState({ cohort: newCohort })
    this.props.editCohort(newCohort)
  })

  onSetRolloutValue = (event: any) => {
    const newCohort = { ...this.state.cohort, rolloutValue: parseInt(event.target.value) }
    this.setState({ cohort: newCohort })
    this.props.editCohort(newCohort)
    const valid = this.validateForm(event.target.value)
    if (!valid) {
      this.setState({ validationErrors: { ...this.state.validationErrors, rolloutPercentage: true }})
      this.props.toggleValidationError('rolloutPercentage')
      return
    }
    this.props.toggleValidationError(undefined)
    this.setState({ validationErrors: { ...this.state.validationErrors, rolloutPercentage: false }})
  }

  onSetStickinessPropertyValue = (event: any) => {
    const newCohort = { ...this.state.cohort, stickinessProperty: event.target.value }
    this.setState({ cohort: newCohort })
    this.props.editCohort(newCohort)
  }

  setCohortData = (cohort: Cohort) => {
    if (cohort) this.setState({ cohort: cohort })
  }

  addCohortCriterion = (event: any) => {
    event.preventDefault()
    const prevCohortCriteria = this.state.cohort.cohortCriteria
    const prevCohortCriteriaSortdById = prevCohortCriteria?.sort((a: ICohortCriterion, b: ICohortCriterion) => (a.cohortCriteriaId > b.cohortCriteriaId) ? 1 : -1)
    const newCohortCriterionCriteriaId = prevCohortCriteriaSortdById && prevCohortCriteriaSortdById.length > 0 ? 
      prevCohortCriteriaSortdById[prevCohortCriteriaSortdById.length - 1].cohortCriteriaId + 1 : 0
    const newCohortCriterion = {
      cohortCriteriaId: newCohortCriterionCriteriaId,
      requiredFieldValues: [],
      requiredFieldName: ''
    }
    const newCohortCriteria = prevCohortCriteria ? [...prevCohortCriteria, newCohortCriterion] : [newCohortCriterion]
    const newCohort = { ...this.state.cohort, cohortCriteria: newCohortCriteria}
    this.setState({ cohort: newCohort })
    this.props.editCohort(newCohort)
  }

  deleteCohortCriterion = (id: number, event: any) => {
    event.preventDefault()
    const prevCohortCriteria = this.state.cohort.cohortCriteria
    const prevCohortCriteriaFiltered = prevCohortCriteria?.filter((cohortCriterion: ICohortCriterion) => cohortCriterion.cohortCriteriaId !== id)
    const newCohort = { ...this.state.cohort, cohortCriteria: prevCohortCriteriaFiltered }
    this.setState({ cohort: newCohort })
    this.props.editCohort(newCohort)
  }

  render() {
    const { cohort, isEditMode } = this.props
    const cohortDetailsReadOnly = <div className="columns">
      <div className="column is-6">
        <div className="field">
          <label className="label is-small">Rollout percentage</label>
          <div className="control">
            <input className="input is-small" type="text" value={cohort.rolloutValue} readOnly />
          </div>
        </div>
      </div>
      <div className="column is-6">
        <div className="field">
          <label className="label is-small">Stickiness property</label>
          <div className="control">
            <input className="input is-small" type="text" value={cohort.stickinessProperty} readOnly />
          </div>
        </div>
      </div>
    </div>

    const cohortDetailsEditMode = <div className="columns">
      <div className="column is-6">
        <div className="field">
          <label className="label is-small">Rollout percentage</label>
          <div className="control">
            <input className={`input is-small ${this.state.validationErrors.rolloutPercentage ? 'is-danger' : ''}`} type="text" defaultValue={cohort.rolloutValue} onChange={this.onSetRolloutValue} />
          </div>
        </div>
      </div>
      <div className="column is-6">
        <div className="field">
          <label className="label is-small">Stickiness property</label>
            <div className="control">
            <div className="select">
              <select onChange={this.onSetStickinessPropertyValue} defaultValue={cohort.stickinessProperty}>
                <option>ffUserId</option>
                <option>appUserId</option>
              </select>
            </div>
          </div>
        </div>

      </div>
    </div>

    const addIcon = <FontAwesomeIcon icon={['fas', 'plus']} />
    const deleteIcon = <FontAwesomeIcon icon={['fas', 'times']} />

    const cohortDetails = isEditMode ? cohortDetailsEditMode : cohortDetailsReadOnly

    return (
      cohort && <div className="columns">
        <div className="column is-9">
          <div className="box cohortDetails">
            <h4>Cohort Id: {cohort.cohortId}</h4>
            {
              cohortDetails
            }
            <h5>Cohort criteria</h5>
            {
              cohort.cohortCriteria && cohort.cohortCriteria.map((criterion: ICohortCriterion) => {
                return <CohortCriterion 
                  key={`cohort-criterion-${criterion.cohortCriteriaId}`}
                  cohortCriterion={criterion}
                  isEditMode={this.props.isEditMode}
                  editCohortCriterion={this.editCohortCriterion}
                  addCohortCriterion={this.addCohortCriterion}
                  deleteCohortCriterion={this.deleteCohortCriterion}
                />
              })
            }
            {
              isEditMode && <button className="button is-success is-small" onClick={event => this.addCohortCriterion(event)}>
                <span className="icon is-small">
                  { addIcon }
                </span>
                <span>Add cohort criterion</span>
              </button>
            }
          </div>
        </div>
        <div className="column is-3">
          <div className="column is-2 delete-btn" onClick={event => this.props.deleteCohort(this.props.cohort.cohortId, event)}>
            {
              isEditMode && <button className="button is-danger is-outlined is-small">
                <span>Delete</span>
                <span className="icon is-small">
                  {deleteIcon}
                </span>
              </button>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default CohortDetails