import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router'
import { Auth } from 'aws-amplify'
import FormErrors from '../../FormErrors'
import Validate from '../../../util/formValidation'
import './index.css'

type ForgotPasswordProps = {}

type Props = ForgotPasswordProps & RouteComponentProps

type ForgotPasswordState = {
  email: string
  errors: any
}

class ForgotPassword extends Component<Props, ForgotPasswordState> {
  state = {
    email: '',
    errors: {
      cognito: null,
      blankfield: false
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    })
  }

  forgotPasswordHandler = async (event: any) => {
    event.preventDefault()

    // Form validation
    this.clearErrorState()
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      })
    }

    // AWS Cognito integration
    try {
      await Auth.forgotPassword(this.state.email)
      this.props.history.push('/forgotpasswordverification')
    }catch(error) {
      console.log(error)
    }
  }

  onInputChange = (event: any) => {
    // @ts-ignore
    this.setState({
      [event.target.id]: event.target.value
    })
    document.getElementById(event.target.id)?.classList.remove('is-danger')
  }

  render() {
    return (
      <section className="section auth">
        <div className="container has-text-left">
          <h2>Forgot your password?</h2>
          <p>
            Please enter the email address associated with your account and we'll
            email you a password reset link.
          </p>
          <FormErrors formerrors={this.state.errors} />

          <form className="pt-4" onSubmit={this.forgotPasswordHandler}>
            <div className="field">
              <p className="control has-icons-left has-icons-right">
                <input
                  type="email"
                  className="input"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={this.state.email}
                  onChange={this.onInputChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-envelope"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control">
                <button className="button is-success">
                  Submit
                </button>
              </p>
            </div>
          </form>
        </div>
      </section>
    )
  }
}

export default ForgotPassword