import React from 'react'
import { useHistory } from 'react-router-dom'
import { Flag } from '../../types/Flag'
import './index.css'

type FlagCardProps = {
  flag: Flag
  resetFlagsStatus?: () => void
}

const FlagCard: React.FC<FlagCardProps> = (props) => {
  const history = useHistory()

  const handleCardClick = (id: string) => {
    if (props.resetFlagsStatus) props.resetFlagsStatus()
    history.push(`/flag/${id}`)
  }
  
  return (
    <div onClick={() => handleCardClick(props.flag.id)} className="tile flag-card is-child box has-background-white">
      <h5>{props.flag.name}</h5>
      <div className="columns flag-info">
        <div className="column is-6">
          <p>Id: {props.flag.id}</p>
        </div>
        <div className="column is-6 has-text-right">
          <p><span>Type:</span> {props.flag.type}</p>
        </div>
      </div>
      <div className="columns">
        <div className="column">
        <div className="field is-grouped is-grouped-multiline">
          <div className="control">
            <div className="tags has-addons">
              <span className="tag is-dark">In use</span>
              <span className="tag is-success">{props.flag.active ? 'yes' : 'no'}</span>
            </div>
          </div>
          <div className="control">
            <div className="tags has-addons">
              <span className="tag is-dark">undeployed changes</span>
              <span className="tag is-primary">{props.flag.hasUndeployedChange ? 'yes' : 'no'}</span>
            </div>
          </div>
          </div>            
        </div>
        
      </div>
    </div>
  )
}

export default FlagCard