import { Auth } from 'aws-amplify'
import { AuthCredentials } from '../types/Auth'
import { 
  AppActions,
  SIGNIN_START, 
  SIGNIN_SUCCESS, 
  SIGNIN_ERROR,
  SIGNOUT_START,
  SIGNOUT_SUCCESS,
  SIGNOUT_ERROR,
  SET_USER_START,
  SET_AUTH_STATUS_START
} from '../types/actions'
import { Dispatch } from 'redux'
import { AppState } from '../store/configureStore'
import { DEFAULT_NETWORK_ERROR } from '../config/constants'

export const signInStart = (): AppActions => ({
  type: SIGNIN_START
})

export const signInSuccess = (user: any): AppActions => ({
  type: SIGNIN_SUCCESS,
  user
})

export const signInError = (error: string): AppActions => ({
  type: SIGNIN_ERROR,
  error
})

export const signOutStart = (): AppActions => ({
  type: SIGNOUT_START
})

export const signOutSuccess = (): AppActions => ({
  type: SIGNOUT_SUCCESS
})

export const signOutError = (error: string): AppActions => ({
  type: SIGNOUT_ERROR, 
  error
})

export const setAuthStatusStart = (isAuthorized: boolean): AppActions => ({
  type: SET_AUTH_STATUS_START,
  isAuthorized
})

export const setUserStart = (user: any): AppActions => ({
  type: SET_USER_START,
  user
})

export const signIn = (credentials: AuthCredentials) => {
  return async(dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(signInStart())
    try {
      const user = await Auth.signIn(credentials.username, credentials.password)
      const jwtToken = user?.signInUserSession?.idToken?.jwtToken
      localStorage.setItem('cognitoIdTokenJwtToken', jwtToken)
      return dispatch(signInSuccess(user))
    } catch (error) {
      let err = null
      !error.message ? err = { "message": error } : err = error
      if (!(err)) err = DEFAULT_NETWORK_ERROR
      return dispatch(signInError(err))
    }
  }
}

export const signOut = () => {
  return async(dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(signOutStart())
    try {
      const user = await Auth.signOut()
      localStorage.removeItem('cognitoIdTokenJwtToken')
      return dispatch(signOutSuccess())
    } catch (error) {
      let err = null
      !error.message ? err = { "message": error } : err = error
      if (!(err)) err = DEFAULT_NETWORK_ERROR
      return dispatch(signOutError(err))
    }
  }
}

export const setAuthStatus = (isAuthorized: boolean) => {
  return async(dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(setAuthStatusStart(isAuthorized))
  }
}

export const setUser = (user: any) => {
  return async(dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(setUserStart(user))
  }
}
