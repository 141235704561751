import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import CreateFlag from '../CreateFlag'
import FlagDetails from '../FlagDetails'
import FlagsList from '../FlagsList'
import Footer from '../Footer'
import Navbar from '../Navbar'
import Register from '../auth/Register'
import SignIn from '../auth/SignIn'
import Welcome from '../auth/Welcome'
import ForgotPassword from '../auth/ForgotPassword'
import ForgotPasswordVerification from '../auth/ForgotPasswordVerification'
import ChangePassword from '../auth/ChangePassword'
import ChangePasswordConfirmation from '../auth/ChangePasswordConfirmation'
import WorkspaceDetails from '../WorkspaceDetails'
import WorkspaceDiff from '../WorkspaceDiff'
import Workspaces from '../Workspaces'
import HomePage from '../HomePage'
import { connect } from 'react-redux'
import { setWorkspaces } from '../../actions/workspaces'
import { setFlags } from '../../actions/flags'
import { setAuthStatus, setUser } from '../../actions/auth'
import { Workspace } from '../../types/Workspace'
import { Flag } from '../../types/Flag'
import { AppState } from '../../store/configureStore'
import { ThunkDispatch } from 'redux-thunk'
import { AppActions } from '../../types/actions'
import { IReducerDefaultStateBase } from '../../types/reducers'
import { bindActionCreators } from 'redux'
import { Auth } from 'aws-amplify'
import config from '../../config/config'

type RootContainerProps = {
}

type RootContainerState = {
  user: any
}

type Props = RootContainerProps & LinkStateProps & LinkDispatchProps

function AdminGuardedRoute(user: any | null) {
  // @ts-ignore
  return function({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props => (!!user ? <Component {...props} /> : <HomePage  {...props} />)}
      />
    )
  }
}

class RootContainer extends React.Component<Props, RootContainerState> {
  state: RootContainerState = {
    user: null
  }

  componentDidMount = async () => {
    try {
      const session = await Auth.currentSession()
      this.props.setAuthStatus(true)
      const user = await Auth.currentAuthenticatedUser()
      this.props.setUser(user)
      this.setState({ user: user })
    } catch(error) {
      if (error !== 'No current user') {
        console.log(error)
      }
    }

    if (this.state.user) {
      const hydrateFromCache = config?.app?.enableClientCaching
      this.props.setWorkspaces(hydrateFromCache)
      this.props.setFlags(hydrateFromCache)
    }
    
  }

  render() {
    const user = this.state.user
    const AdminRoute = AdminGuardedRoute(user)
    return (
      <Router>
        <div className="main-container">
          <Navbar />
          <Switch>
            <Route exact path='/changepassword' component={ChangePassword} />
            <Route exact path='/changepasswordconfirmation' component={ChangePasswordConfirmation} />
            <Route exact path='/forgotpasswordverification' component={ForgotPasswordVerification} />
            <Route exact path='/forgotpassword' component={ForgotPassword} />
            <Route exact path='/login' render={(props) => <SignIn {...props} />} />
            <Route exact path='/register' render={(props) => <Register {...props} />} />
            <Route exact path='/welcome' component={Welcome} />
            <AdminRoute exact path='/flag/:id' component={FlagDetails} />
            <AdminRoute exact path='/flags' component={FlagsList} />
            <AdminRoute exact path='/workspaces/showdiff/:id' component={WorkspaceDiff} />
            <AdminRoute exact path='/workspace/:id/createflag' component={CreateFlag} />
            <AdminRoute exact path='/workspace/:id' component={WorkspaceDetails} />
            <AdminRoute exact path='/workspaces' component={Workspaces} />
            <Route exact path='/' render={(props) => <HomePage {...props} />} />
          </Switch>
        </div>
        <Footer />
      </Router>
    )
  }

}

interface LinkStateProps {
  workspaces: IReducerDefaultStateBase<Workspace>
  flags: IReducerDefaultStateBase<Flag>
}

interface LinkDispatchProps {
  setWorkspaces: (hydrateFromCache: boolean) => void
  setFlags: (hydrateFromCache: boolean) => void
  setAuthStatus: (isAuthorized: boolean) => void
  setUser: (user: any) => void
}

const mapStateToProps = (
  state: AppState,
  ownProps: RootContainerProps
): LinkStateProps => ({
  workspaces: state.entities.workspaces,
  flags: state.entities.flags
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: RootContainerProps
): LinkDispatchProps => ({
  setWorkspaces: bindActionCreators(setWorkspaces, dispatch),
  setFlags: bindActionCreators(setFlags, dispatch),
  setAuthStatus: bindActionCreators(setAuthStatus, dispatch),
  setUser: bindActionCreators(setUser, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RootContainer)
