

import { Cohort } from '../types/Flag'
import { CreateFlagState } from '../components/CreateFlag'
import { FlagDetailsState } from '../components/FlagDetails'

interface IFlagFormValidationResults {
  isValid: boolean;
  errors: {
    blankField: boolean;
    rolloutPercentage: boolean;
  }
}

export const validateForm = (state: CreateFlagState | FlagDetailsState): IFlagFormValidationResults => {
  const results = {
    isValid: true,
    errors: {
      blankField: false,
      rolloutPercentage: false
    }
  }

  if (state.flag && (state.flag.flagId === "" || state.flag.name === "")) {
    results.isValid = false
    results.errors.blankField = true
  }

  if (state.flag.cohorts && state.flag.cohorts.length > 0) {
    state.flag.cohorts.forEach((cohort: Cohort) => {
      // allow string percentage value with any number of decimal points
      const globalRegex = RegExp(/(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,})?$)/, 'g')
      const result = globalRegex.test(cohort.rolloutValue.toString())
      if (!result) {
        results.isValid = false
        results.errors.rolloutPercentage = true 
      }
    })
  }

  return results
}