export default {
  "app": {
    "enableClientCaching": false
  },
  "apis": { 
    "featureFlagging": {
      "workspacesEndpoint": process.env.REACT_APP_FEATURE_FLAGGING_WORKSPACES_ENDPOINT || '',
      "flagsEndpoint": process.env.REACT_APP_FEATURE_FLAGGING_FLAGS_ENDPOINT || '',
      "deployConfigEndpoint": process.env.REACT_APP_FEATURE_FLAGGING_DEPLOY_CONFIG_ENDPOINT || '',
      "replaceConfigEndpoint": process.env.REACT_APP_FEATURE_FLAGGING_REPLACE_CONFIG_ENDPOINT || ''
    }
  },
  "cognito": {
    "REGION": process.env.REACT_APP_AWS_COGNITO_REGION || '',
    "USER_POOL_ID": process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID || '',
    "APP_CLIENT_ID": process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID || ''
  }
}