import axios from 'axios'
import { Flag, ICreateFlag } from '../types/Flag'
import { 
  AppActions,
  ADD_FLAG_START, 
  ADD_FLAG_SUCCESS, 
  ADD_FLAG_ERROR,
  EDIT_FLAG_START,
  EDIT_FLAG_SUCCESS,
  EDIT_FLAG_ERROR,
  DELETE_FLAG,
  SET_FLAGS_START,
  SET_FLAGS_SUCCESS,
  SET_FLAGS_ERROR,
  RESET_FLAGS_STATUS_START
} from '../types/actions'
import { Dispatch } from 'redux'
import { AppState } from '../store/configureStore'
import config from '../config/config'
import { DEFAULT_NETWORK_ERROR } from '../config/constants'

const flagsEndpoint = config.apis.featureFlagging.flagsEndpoint

export const setFlagsStart = (): AppActions => ({
  type: SET_FLAGS_START
})

export const setFlagsSuccess = (flags: Flag[]): AppActions => ({
  type: SET_FLAGS_SUCCESS,
  flags
})

export const setFlagsError = (error: string): AppActions => ({
  type: SET_FLAGS_ERROR,
  error
})

export const addFlagStart = (): AppActions => ({
  type: ADD_FLAG_START
})

export const addFlagSuccess = (flag: Flag): AppActions => ({
  type: ADD_FLAG_SUCCESS,
  flag
})

export const addFlagError = (error: string): AppActions => ({
  type: ADD_FLAG_ERROR, 
  error
})

export const editFlagStart = (): AppActions => ({
  type: EDIT_FLAG_START
})

export const editFlagSuccess = (flag: Flag): AppActions => ({
  type: EDIT_FLAG_SUCCESS,
  flag
})

export const editFlagError = (error: string): AppActions => ({
  type: EDIT_FLAG_ERROR,
  error
})

export const deleteFlag = (id: string): AppActions => ({
  type: DELETE_FLAG,
  id
})

export const resetFlagsStatusStart = (): AppActions => ({
  type: RESET_FLAGS_STATUS_START
})

export const addFlag = (flagData: ICreateFlag) => {
  return async(dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(addFlagStart())
    const {
      flagId = "",
      name = "",
      cohorts = [],
      defaultValue = false,
      active = false,
      type = "boolean",
      workspaceId = 0
    } = flagData
    const flag = { flagId, name, cohorts, defaultValue, active, type, workspaceId }

    try {
      const jwtToken = localStorage.getItem('cognitoIdTokenJwtToken')
      const config = {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }
      const resp = await axios.post(flagsEndpoint, flag, config)
      const newFlag: Flag = { ...flag, id: resp.data?.id }
      // update local storage
      const cachedFlags = localStorage.getItem('ffClientFlags')
      if (cachedFlags) {
        const updatedCachedFlags = JSON.parse(cachedFlags)
        const newFlag: Flag = { ...flag, id: resp.data?.id }
        updatedCachedFlags.push(newFlag)
        localStorage.setItem('ffClientFlags', JSON.stringify(updatedCachedFlags))
      }

      return dispatch(addFlagSuccess(newFlag))
    } catch (err) {
      const error = err.message ? err.message : DEFAULT_NETWORK_ERROR
      return dispatch(addFlagError(error))
    }

  }
}

export const startDeleteFlag = (id: string) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(deleteFlag(id))
  }
}

export const editFlag = (flag: Flag) => {
  return async(dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(editFlagStart())
    try {
      const jwtToken = localStorage.getItem('cognitoIdTokenJwtToken')
      const config = {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }
      const resp = await axios.put(`${flagsEndpoint}/${flag.id}`, flag, config)

      // update local storage
      const cachedFlags = localStorage.getItem('ffClientFlags')
      if (cachedFlags) {
        const updatedCachedFlags = JSON.parse(cachedFlags).filter((cachedFlag: Flag) => cachedFlag.id !== flag.id)
        updatedCachedFlags.push(flag)
        localStorage.setItem('ffClientFlags', JSON.stringify(updatedCachedFlags))
      }

      return dispatch(editFlagSuccess(flag))
    } catch (err) {
      const error = err.message ? err.message : DEFAULT_NETWORK_ERROR
      return dispatch(editFlagError(error))
    }
  }
}

export const setFlags = (hydrateFromCache: boolean = false) => {
  return async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(setFlagsStart())
    try {
      // used cached data, if available
      if (hydrateFromCache) {
        const cachedFlags = localStorage.getItem('ffClientFlags')
        if (cachedFlags) return dispatch(setFlagsSuccess(JSON.parse(cachedFlags)))
      }
      // fetch remote data if none cached
      const jwtToken = localStorage.getItem('cognitoIdTokenJwtToken')
      const config = {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }
      const resp = await axios.get(flagsEndpoint, config)
      localStorage.setItem('ffClientFlags', JSON.stringify(resp.data))
      return dispatch(setFlagsSuccess(resp.data))
    } catch (err) {
      return dispatch(setFlagsError(err))
    }
  }
}

export const resetFlagsStatus = () => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(resetFlagsStatusStart())
  }
}
