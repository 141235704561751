import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router'
import FormErrors from '../../FormErrors'
import Validate from '../../../util/formValidation'
import { Auth } from 'aws-amplify'
import './index.css'

type RegisterProps = {}

type Props = RegisterProps & RouteComponentProps

type RegisterState = {
  username: string
  email: string
  password: string
  confirmpassword: string
  errors: any
}

export default class Register extends React.Component<Props, RegisterState> {
  state: RegisterState = {
    username: '',
    email: '',
    password: '',
    confirmpassword: '',
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false
      }
    })
  }

  runFormValidation = (event: any) => {
    this.clearErrorState();

    const error = Validate(event, this.state)
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      })
    }
  }

  handleSubmit = async (event: any) => {
    event.preventDefault()

    this.runFormValidation(event)

    // AWS Cognito integration
    const { username, email, password } = this.state;
    try {
      const signUpResponse = await Auth.signUp({
        username,
        password,
        attributes: {
          email: email
        }
      })
      this.props.history.push('/welcome')
    } catch (error) {
      let err = null
      !error.message ? err = { 'message': error } : err = error
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err
        }
      })
    }
  }

  onInputChange = (event: any) => {
    // @ts-ignore
    this.setState({ [event.target.id]: event.target.value })
    if (event.target.id === 'username') {
      this.setState({
        'email': event.target.value
      })
    }
    document.getElementById(event.target.id)?.classList.remove('is-danger')

    // this.runFormValidation(event)
  }

  render() {
    return (
      <section className="section auth">
        <div className="container has-text-left">
          <div className="columns">
            <div className="column">
              <h2>Register</h2>
              <p className="required">All fields required</p>
              <FormErrors formerrors={this.state.errors} />
              <form className="pt-3 register" onSubmit={this.handleSubmit}>
                <div className="field">
                  <p className="control">
                    <input 
                      className="input" 
                      type="text"
                      id="username"
                      aria-describedby="userNameHelp"
                      placeholder="Enter email address"
                      value={this.state.username}
                      onChange={this.onInputChange}
                      required
                    />
                  </p>
                </div>
                <div className="field">
                  <p className="control has-icons-left">
                    <input 
                      className="input" 
                      type="password"
                      id="password"
                      placeholder="Password"
                      value={this.state.password}
                      onChange={this.onInputChange}
                      required
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-lock"></i>
                    </span>
                  </p>
                </div>
                <div className="field">
                  <p className="control has-icons-left">
                    <input 
                      className="input" 
                      type="password"
                      id="confirmpassword"
                      placeholder="Confirm password"
                      value={this.state.confirmpassword}
                      onChange={this.onInputChange}
                      required
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-lock"></i>
                    </span>
                  </p>
                </div>
                <div className="field">
                  <p className="control">
                    <button className="button is-success">
                      Register
                    </button>
                  </p>
                </div>
              </form>
            </div>
            <div className="column">
              <div className="container has-text-left password-rules">
                Password must contain
                <ul className="password-list">
                  <li>At least 8 characters</li>
                  <li>At least 1 number</li>
                  <li>At least 1 special character</li>
                  <li>At least 1 uppercase letter</li>
                  <li>At least 1 lowercase letter</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
