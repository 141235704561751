import React from 'react'
import './index.css'

type SuccessMessageProps = {
  message: string
}

const SuccessMessage: React.FC<SuccessMessageProps> = (props) => {
  const { message } = props
  return (
    <div className="success-message-container">
      <div className="success-message from-server">
        { message }
      </div>
    </div>
  )
}

export default SuccessMessage
