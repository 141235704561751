import axios from 'axios'
import { Workspace } from '../types/Workspace'
import { AppActions, 
  SET_WORKSPACES_START, 
  SET_WORKSPACES_SUCCESS,
  SET_WORKSPACES_ERROR, 
  DEPLOY_WORKSPACE_CONFIG_START, 
  DEPLOY_WORKSPACE_CONFIG_SUCCESS, 
  DEPLOY_WORKSPACE_CONFIG_FAIL,
  REPLACE_WORKSPACE_CONFIG_START, 
  REPLACE_WORKSPACE_CONFIG_SUCCESS, 
  REPLACE_WORKSPACE_CONFIG_FAIL,
  SHOW_WORKSPACE_DIFF_START,
  SHOW_WORKSPACE_DIFF_FAIL,
  SHOW_WORKSPACE_DIFF_SUCCESS
} from '../types/actions'
import { Dispatch } from 'redux'
import { AppState } from '../store/configureStore'
import config from '../config/config'
import { DEFAULT_NETWORK_ERROR } from '../config/constants'

const getWorkspacesEndpoint = config.apis.featureFlagging.workspacesEndpoint
const deployConfigEndpoint = config.apis.featureFlagging.deployConfigEndpoint
const replaceConfigEndpoint = config.apis.featureFlagging.replaceConfigEndpoint

export const setWorkspacesStart = (): AppActions => ({
  type: SET_WORKSPACES_START
})

export const setWorkspacesSuccess = (workspaces: Workspace[]): AppActions => ({
  type: SET_WORKSPACES_SUCCESS,
  workspaces
})

export const setWorkspacesError = (error: string): AppActions => ({
  type: SET_WORKSPACES_ERROR,
  error
})

export const deployWorkspaceConfigStart = (): AppActions => ({
  type: DEPLOY_WORKSPACE_CONFIG_START
})

export const deployWorkspaceConfigSuccess = (): AppActions => ({
  type: DEPLOY_WORKSPACE_CONFIG_SUCCESS
})

export const deployWorkspaceConfigFail = (error: string): AppActions => ({
  type: DEPLOY_WORKSPACE_CONFIG_FAIL,
  error
})

export const replaceWorkspaceConfigStart = (): AppActions => ({
  type: REPLACE_WORKSPACE_CONFIG_START
})

export const replaceWorkspaceConfigSuccess = (): AppActions => ({
  type: REPLACE_WORKSPACE_CONFIG_SUCCESS
})

export const replaceWorkspaceConfigFail = (error: string): AppActions => ({
  type: REPLACE_WORKSPACE_CONFIG_FAIL,
  error
})

export const showWorkspaceDiffStart = (): AppActions => ({
  type: SHOW_WORKSPACE_DIFF_START
})

export const showWorkspaceDiffSuccess = (html: string): AppActions => ({
  type: SHOW_WORKSPACE_DIFF_SUCCESS,
  html
})

export const showWorkspaceDiffFail = (error: string): AppActions => ({
  type: SHOW_WORKSPACE_DIFF_FAIL,
  error
})

export const setWorkspaces = (hydrateFromCache: boolean = false) => {
  return async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(setWorkspacesStart())
    try {
      // used cached data, if available
      if (hydrateFromCache) {
        const cachedWorkspaces = localStorage.getItem('ffClientWorkspaces')
        if (cachedWorkspaces) return dispatch(setWorkspacesSuccess(JSON.parse(cachedWorkspaces)))
      }
      // fetch remote data if none cached
      const jwtToken = localStorage.getItem('cognitoIdTokenJwtToken')
      const config = {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }
      const resp = await axios.get(getWorkspacesEndpoint, config)
      localStorage.setItem('ffClientWorkspaces', JSON.stringify(resp.data))
      return dispatch(setWorkspacesSuccess(resp.data))
    } catch (err) {
      return dispatch(setWorkspacesError(err))
    }
  }
}

export const deployWorkspaceConfig = (workspaceName: string) => {
  return async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(deployWorkspaceConfigStart())
    try {
      const jwtToken = localStorage.getItem('cognitoIdTokenJwtToken')
      const config = {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }
      await axios.get(`${deployConfigEndpoint}/${workspaceName}`, config)
      return dispatch(deployWorkspaceConfigSuccess())
    } catch (err) {
      const error = err.message ? err.message : DEFAULT_NETWORK_ERROR
      return dispatch(deployWorkspaceConfigFail(error))
    }
  }
}

export const replaceWorkspaceConfig = (sourceWorkspaceId: string, destinationWorkspaceId: string) => {
  return async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(replaceWorkspaceConfigStart())
    try {
      const jwtToken = localStorage.getItem('cognitoIdTokenJwtToken')
      const config = {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }
      const data = {
        'deployFromWorkspaceId': parseInt(sourceWorkspaceId)
      }
      await axios.put(`${replaceConfigEndpoint}/${destinationWorkspaceId}`, data, config)
      return dispatch(replaceWorkspaceConfigSuccess())
    } catch (err) {
      const error = err.message ? err.message : DEFAULT_NETWORK_ERROR
      return dispatch(replaceWorkspaceConfigFail(error))
    }
  }
}

export const showWorkspaceDiff = (endpoint: string) => {
  return async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    dispatch(showWorkspaceDiffStart())
    try {
      const jwtToken = localStorage.getItem('cognitoIdTokenJwtToken')
      const config = {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }
      const resp = await axios.get(endpoint, config)
      
      return dispatch(showWorkspaceDiffSuccess(resp.data))
    } catch (err) {
      const error = err.message ? err.message : DEFAULT_NETWORK_ERROR
      return dispatch(showWorkspaceDiffFail(error))
    }
  }
}