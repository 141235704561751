import { Workspace } from '../types/Workspace'
import { IReducerDefaultStateBase } from '../types/reducers'
import { WorkspaceActionTypes } from '../types/actions'

const workspaceReducerDefaultState: IReducerDefaultStateBase<Workspace> = { 
  byId : {}, 
  allIds : [],
  diffHTML: '',
  status: 'idle',
  error: null
}

const workspaceReducer = (state = workspaceReducerDefaultState, action: WorkspaceActionTypes): IReducerDefaultStateBase<Workspace> => {
  const newState = { ...state }
  switch (action.type) {
    case 'SET_WORKSPACES_START':
      newState.status = 'pending'
      return newState
    case 'SET_WORKSPACES_SUCCESS':
      if (action.workspaces && action.workspaces.length > 0) {
        action.workspaces.forEach((workspace: Workspace) => {
          newState.allIds.push(workspace.id.toString())
          newState.byId[workspace.id.toString()] = workspace
        })
      }
      newState.status = 'idle'
      return newState
    case 'SET_WORKSPACES_ERROR':
      newState.status = 'failed'
      return newState
    case 'DEPLOY_WORKSPACE_CONFIG_START':
      newState.status = 'pending'
      return newState
    case 'DEPLOY_WORKSPACE_CONFIG_SUCCESS':
      newState.status = 'succeeded'
      return newState
    case 'DEPLOY_WORKSPACE_CONFIG_FAIL':
      newState.status = 'failed'
      if (action.error) newState.error = action.error
      return newState
    case 'REPLACE_WORKSPACE_CONFIG_START':
      newState.status = 'pending'
      return newState
    case 'REPLACE_WORKSPACE_CONFIG_SUCCESS':
      newState.status = 'succeeded'
      return newState
    case 'REPLACE_WORKSPACE_CONFIG_FAIL':
      newState.status = 'failed'
      if (action.error) newState.error = action.error
      return newState
    case 'SHOW_WORKSPACE_DIFF_START':
      newState.status = 'pending'
      return newState
    case 'SHOW_WORKSPACE_DIFF_SUCCESS':
      newState.diffHTML = action.html
      newState.status = 'succeeded'
      return newState
    case 'SHOW_WORKSPACE_DIFF_FAIL':
      newState.status = 'failed'
      if (action.error) newState.error = action.error
    default:
      return state
  }
}

export { workspaceReducer }