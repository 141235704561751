import { Flag } from '../types/Flag'
import { IReducerDefaultStateBase } from '../types/reducers'
import { FlagActionTypes } from '../types/actions'

const flagsReducerDefaultState: IReducerDefaultStateBase<Flag> = { 
  byId : {}, 
  allIds : [], 
  status: 'idle',
  error: null
}

const flagReducer = (state = flagsReducerDefaultState, action: FlagActionTypes): IReducerDefaultStateBase<Flag> => {
  const newState = { ...state }
  switch (action.type) {
    case 'ADD_FLAG_START':
      newState.status = 'pending'
      return newState
    case 'ADD_FLAG_SUCCESS':
      if (action.flag) {
        newState.allIds.push(action.flag.id)
        newState.byId[action.flag.id.toString()] = action.flag
      }
      newState.status = 'succeeded'
      return newState
    case 'ADD_FLAG_ERROR':
        newState.status = 'failed'
        return newState
    case 'EDIT_FLAG_START':
        newState.status = 'pending'
        return newState
    case 'EDIT_FLAG_SUCCESS':
      if (action.flag) {
        newState.byId[action.flag.id] = action.flag
      }
      newState.status = 'succeeded'
      return newState
    case 'EDIT_FLAG_ERROR':
      newState.status = 'failed'
      return newState
    case 'SET_FLAGS_START':
      newState.status = 'pending'
      return newState
    case 'SET_FLAGS_SUCCESS':
      if (action.flags && action.flags.length > 0) {
        action.flags.forEach((flag: Flag) => {
          newState.allIds.push(flag.id)
          newState.byId[flag.id.toString()] = flag
        })
      }
      newState.status = 'idle'
      return newState
    case 'SET_FLAGS_ERROR':
      newState.status = 'failed'
      return newState
    case 'RESET_FLAGS_STATUS_START':
      newState.status = 'idle'
      newState.error = null
      return newState
    default:
      return state
  }
}

export { flagReducer }