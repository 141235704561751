import { AuthState } from '../types/Auth'
import { AuthActionTypes } from '../types/actions'

const authReducerDefaultState: AuthState = { 
  isAuthenticated: false,
  isAuthenticating: false,
  user: null,
  error: null
}

const authReducer = (state = authReducerDefaultState, action: AuthActionTypes) => {
  const newState = { ...state }
  switch (action.type) {
    case 'SIGNIN_START':
    case 'SIGNOUT_START':
      newState.isAuthenticating = true
      newState.error = null
      return newState
    case 'SIGNIN_SUCCESS':
      newState.isAuthenticating = false
      newState.isAuthenticated = true
      newState.user = action.user
      newState.error = null
      return newState
    case 'SIGNIN_ERROR':
      newState.error = action.error
      return newState
    case 'SIGNOUT_SUCCESS':
        newState.isAuthenticating = false
        newState.isAuthenticated = false
        newState.user = null
        newState.error = null
      return newState
    case 'SIGNOUT_ERROR':
        newState.error = action.error
      return newState
    case 'SET_AUTH_STATUS_START':
      newState.user = null
      newState.error = null
      newState.isAuthenticating = false
      if (action.isAuthorized === true) {
        newState.isAuthenticated = true
      } else {
        newState.isAuthenticated = false
      }
      return newState
    case 'SET_USER_START':
      newState.isAuthenticating = false
      newState.isAuthenticated = true
      newState.user = action.user
      newState.error = null
      return newState
    default:
      return state
  }
}

export { authReducer }