import React from 'react'
import './index.css'
import { Link } from 'react-router-dom'
import { Workspace } from '../../types/Workspace'

type WorkspaceCardProps = {
  workspace: Workspace;
  flagQty: number;
  hasUndeployedChanges: boolean;
}

const WorkspaceCard: React.FC<WorkspaceCardProps> = (props) => {
  return (
    <div className="column is-4">
      <Link to={`/workspace/${props.workspace.id}`}>
        <div className="card">
          <div className="card-content">
            <div className="has-text-left">
              <div className="columns">
                <div className="column">
                  <p className="workspace-card-label">workspace name:</p>
                  <h2>{props.workspace.workspace}</h2>
                </div>
                <div className="column has-text-right">
                  <p className="workspace-card-label">workspace id:</p>
                  <h2>{props.workspace.id}</h2>
                </div>
              </div>
            </div>
            <div className="card-content">
              <div className="field is-grouped is-grouped-multiline">
                <div className="control">
                  <div className="tags has-addons">
                  <span className="tag is-dark">flags</span>
                    <span className="tag is-success">{props.flagQty}</span>
                  </div>
                </div>
                <div className="control">
                  <div className="tags has-addons">
                    <span className="tag is-dark">undeployed changes</span>
                    <span className="tag is-primary">{props.hasUndeployedChanges ? 'yes' : 'no'}</span>
                  </div>
                </div>
              </div>
            </div>
            <button className="button is-link">Open workspace</button>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default WorkspaceCard