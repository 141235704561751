import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk, { ThunkMiddleware } from 'redux-thunk'
import { flagReducer } from '../reducers/flags'
import { workspaceReducer } from '../reducers/workspaces'
import { authReducer } from '../reducers/auth'
import { AppActions } from '../types/actions'
import { composeWithDevTools } from 'redux-devtools-extension'

const entitiesReducer = combineReducers({
  flags: flagReducer,
  workspaces: workspaceReducer,
  auth: authReducer
})

export const rootReducer = combineReducers({
  entities: entitiesReducer
})

export type AppState = ReturnType<typeof rootReducer>

export const store = createStore(
  rootReducer, 
  composeWithDevTools(
    applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>)
  )
)