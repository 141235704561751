import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { AppState } from '../../store/configureStore'
import { ThunkDispatch } from 'redux-thunk'
import { AppActions } from '../../types/actions'
import { signIn } from '../../actions/auth'
import { AuthState, AuthCredentials } from '../../types/Auth'
import { Auth } from 'aws-amplify'
import { bindActionCreators } from 'redux'
import FormErrors from '../FormErrors'
import Validate from '../../util/formValidation'
import './index.css'

type HomePageProps = {}

type Props = HomePageProps & RouteComponentProps & LinkStateProps & LinkDispatchProps

type HomePageState = {
  username: string
  password: string
  errors: any
}

class HomePage extends Component<Props, HomePageState> {
  state = {
    username: "",
    password: "",
    errors: {
      blankfield: false,
      cognito: null
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        blankfield: false,
        cognito: null
      }
    })
  }

  handleSubmit = async (event: any) => {
    event.preventDefault()

    // Form validation
    this.clearErrorState()
    const error = Validate(event, this.state)
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      })
    }

    const credentails: AuthCredentials = {
      username: this.state.username,
      password: this.state.password
    }

    await this.props.signIn(credentails)

    if (this.props.auth.error) {
      this.setState({
        errors: { ...this.state.errors, cognito: this.props.auth.error }
      })
    } else {
      window.location.href = '/workspaces'
    }
  }

  onInputChange = (event: any) => {
    // @ts-ignore
    this.setState({ [event.target.id]: event.target.value })
    document.getElementById(event.target.id)?.classList.remove("is-danger")
  }

  render() {
    return (
      <section className="section auth homepage hero is-dark">
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title">
              Welcome to feature<br /> flag mission control
            </h1>
            <p>Log in to initiate launch sequence</p>
            <div className="form-container">
              <FormErrors formerrors={this.state.errors} />
              <form className="pt-5" onSubmit={this.handleSubmit}>
                <div className="field">
                  <p className="control">
                    <input 
                      className="input" 
                      type="text"
                      id="username"
                      aria-describedby="usernameHelp"
                      placeholder="Enter username or email"
                      value={this.state.username}
                      onChange={this.onInputChange}
                    />
                  </p>
                </div>
                <div className="field">
                  <p className="control has-icons-left">
                    <input 
                      className="input" 
                      type="password"
                      id="password"
                      placeholder="Password"
                      value={this.state.password}
                      onChange={this.onInputChange}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-lock"></i>
                    </span>
                  </p>
                </div>
                <div className="field">
                  <p className="control">
                    <a href="/forgotpassword">Forgot password?</a>
                  </p>
                </div>
                <div className="field">
                  <p className="control">
                    <button className="button is-success mt-2">
                      Log in
                    </button>
                  </p>
                </div>
              </form>
              <p className="mt-4">Don't have an account? <a href="/register">Sign up</a></p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

interface LinkStateProps {
  auth: AuthState
}

interface LinkDispatchProps {
  signIn: (credentials: AuthCredentials) => void
}

const mapStateToProps = (
  state: AppState,
  ownProps: HomePageProps
): LinkStateProps => ({
  auth: state.entities.auth
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: HomePageProps
): LinkDispatchProps => ({
  signIn: bindActionCreators(signIn, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage)